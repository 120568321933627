import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useNoteStore = defineStore('note', {
  state: () => ({ listNotes: 0, profNotes: [], note: 0, loading: false, loadingAdd: false }),
  actions: {
    listNoteCourses(slug) {
        this.loading = true;
      axios.get(`/api/course-notes/${slug}`).then((response) => {
        this.listNotes = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    listNoteProf(pseudo) {
      this.loading = true;
      axios.get(`/api/prof-notes/${pseudo}`).then((response) => {
        this.profNotes = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    checkNote(body) {
      this.loading = true;
      axios.post(`/api/check-note-course`, body).then((response) => {
        return response.data.message
      }).catch(() => {
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    addNoteCourses(body,slug) {
      this.loadingAdd = true;
      axios.post(`/api/note-course`, body).then(() => {
        this.listNoteCourses(slug)
        toast.success("Merci d'avoir noter ce cours", {
          autoClose: 3000,
      }); 
        this.loadingAdd = false;
      }).catch(() => {
        this.loadingAdd = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})