<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2 v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layouts2>
    <layoutslogin v-else></layoutslogin>
    <blogmasonry></blogmasonry>
    <!-- Blog Grid -->
    <section v-if="storeBlog.listBlog.length > 0" class="course-content">
      <div class="container">
        <div class="row masonry-blog-blk">
          <div v-for="(item,index) in storeBlog.listBlog" :key="index" class="col-lg-4 col-md-6">
            <!-- Blog Post -->
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }"
                  ><img
                    class="img-fluid"
                    :src="item.image"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />{{ item.created_at }}
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />{{ item.categorie }}
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }"
                    >{{ item.title }}</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    {{ item.content.substring(0, 100) }} […]
                  </p>
                  <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }" class="read-more btn btn-primary"
                    >Lire la suite</router-link
                  >
                </div>
              </div>
            </div>
            <!-- /Blog Post -->
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link to="blog-details"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-09.jpg"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />May 24, 2023
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />Programming, Courses
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link to="blog-details"
                    >Expand Your Career Opportunities With Python</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                    hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet
                    vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin
                    laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor
                    eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. Sed egestas,
                    ante et vulputate volutpat, eros pede […]
                  </p>
                  <router-link to="blog-details" class="read-more btn btn-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link to="blog-details"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-09.jpg"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />May 24, 2023
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />Programming, Courses
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link to="blog-details"
                    >Expand Your Career Opportunities With Python</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                    hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
                  </p>
                  <router-link to="blog-details" class="read-more btn btn-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link to="blog-details"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-10.jpg"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />Jun 14, 2023
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />Programming, Web Design
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link to="blog-details"
                    >Complete PHP Programming Career Guideline</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                    hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
                  </p>
                  <router-link to="blog-details" class="read-more btn btn-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link to="blog-details"
                  ><img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-13.jpg"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />Feb 14, 2023
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />Programming, Courses
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link to="blog-details"
                    >Learn Mobile Applications Development from Experts</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                    hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
                  </p>
                  <router-link to="blog-details" class="read-more btn btn-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Load button -->
        <div @click="storeBlog.loading === true ? page : page++" v-if="storeBlog.empty !== true" class="load-more text-center">
          <div v-if="storeBlog.loading === true" class="spinner-border" role="status">
            <span class="sr-only">Chargement...</span>
          </div>
          <span class="btn btn-primary" v-else>
            Voir plus
          </span>
        </div>
        <!-- /Load button -->
      </div>
    </section>
    <!-- /Blog Grid -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useBlogStore } from '@stores/blog'
  import { useAuthStore } from '@stores/auth'
  export default {
    data() {
      return {
        storeAuth: useAuthStore(),
        storeBlog: useBlogStore(),
        page: 1,
      };
    },
    watch: {
      async page(newValue) {
        await this.storeBlog.list(newValue)
      }
    },
    async mounted() {
      await this.storeBlog.list(this.page)
    },

    methods: {
      
    },
  };
</script>