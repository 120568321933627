<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <!-- <registerbanner></registerbanner> -->

      <div class="login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox register-box">
            <div class="img-logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <router-link to="login">Retourner à l'accueil</router-link>
              </div>
            </div>
            <h1>Finalisez votre profil enseignant</h1>
            <div class="row">
              <div class="col-lg-5">
                <div class="profile-box">
                  <div class="circle-bar circle-bar1 text-center">
                    <div class="circle-graph1" data-percent="30">
                      <circle-progress :percent="30" />
                      <p>30% <span>1 de 3</span></p>
                    </div>
                  </div>
                  <h3>Complément de profil</h3>
                  <div class="personal-detail d-flex align-items-center">
                    <span class="active-color active-bar">1</span>
                    <div class="personal-text">
                      <h4>Catégories et Profil</h4>
                      <p class="mb-0">Configurer votre profil et choisissez votre catégorie</p>
                    </div>
                  </div>
                  <div class="personal-detail d-flex align-items-center">
                    <span>2</span>
                    <div class="personal-text">
                      <h4>Profils sociaux</h4>
                      <p class="mb-0">Configurer les liens de vos profils sociaux</p>
                    </div>
                  </div>
                  <div class="personal-detail d-flex align-items-center">
                    <span>3</span>
                    <div class="personal-text">
                      <h4>Biographie</h4>
                      <p class="mb-0">Entrez votre biographie ici</p>
                    </div>
                  </div>
                  <!-- <div class="personal-detail d-flex align-items-center">
                    <span>3</span>
                    <div class="personal-text">
                      <h4>Profile Privacy Settings</h4>
                      <p class="mb-0">Setup Your Profile Privacy Settings</p>
                    </div>
                  </div>
                  <div class="personal-detail d-flex align-items-center">
                    <span>4</span>
                    <div class="personal-text">
                      <h4>Link Accounts</h4>
                      <p class="mb-0">Setup Your Linked Accounts</p>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="col-lg-7">
                <div class="personal-form">
                  <h4>Catégorie et Profil</h4>
                  <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="add-course-label">Categories</label>
                          <vue-select :settings="{ multiple: true }" :options="getCategories" v-model="categories" />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="add-course-label">Photo de profil</label>
                          <input @change="handleFileChange" id="formFileLg" accept="image/*" class="form-control form-control-lg" type="file">
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="form-group">
                          <img :src="imageUrl === null ? '@/assets/img/user/user11.jpg' : imageUrl" alt="" class="img-fluid"/>
                        </div>
                      </div> -->
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="add-course-label">Bannière</label>
                          <input @change="handleFileChangeBanner" id="formFileLg" accept="image/*" class="form-control form-control-lg" type="file">
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="form-group">
                          <img :src="bannerUrl === null ? '@/assets/img/user/user11.jpg' : bannerUrl" alt="" class="img-fluid"/>
                        </div>
                      </div> -->
                      <div v-if="isCheck === true" class="col-lg-12 mt-4">
                        <div class="next-btn">
                          <button class="btn btn-primary btn-start" type="submit">
                            Suivant
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { useCategorieStore } from '@stores/categorie'
import { useAuthStore } from '@stores/auth'
export default {
  components: {
    CircleProgress,
  },
  data() {
    return {
      storeCategorie: useCategorieStore(),
      storeAuth: useAuthStore(),
      imageUrl: null,
      bannerUrl: null,
      photoUrl: null,
      banner: null,
      categories: [],
      categoriesList: [],
    };
  },
  async mounted() {
    await this.storeCategorie.courseCategories()
    console.log(this.storeCategorie.courseCategorie)
  },
  computed: {
    isCheck(){
      if(this.photoUrl === null || this.banner === null || this.categories.length < 0){
        return false
      } else {
        return true
      }
    },
    getCategories(){
      let array = []
      if(this.storeCategorie.courseCategorie.length > 0){
        for (let index = 0; index < this.storeCategorie.courseCategorie.length; index++) {
          array.push({
            id: this.storeCategorie.courseCategorie[index].id,
            text: this.storeCategorie.courseCategorie[index].name
          })
          
        }
      }
      return array
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      this.photoUrl = file
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleFileChangeBanner(event) {
      const file = event.target.files[0];
      this.banner = file
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          this.bannerUrl = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      this.storeAuth.prof.photo = this.photoUrl
      this.storeAuth.prof.banner = this.banner
      this.storeAuth.prof.categories = this.categories
      this.$router.push("/register-step-two");
    },
    summernoteChange() {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert() {
      // console.log("summernoteImageLinkInsert()", args);
    },
  },
};
</script>
