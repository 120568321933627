<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructorborder></layoutsinstructorborder>
    <instructorgrid2></instructorgrid2>
    <!-- Page Wrapper -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <instructorfilter></instructorfilter>

            <div v-if="storeProfs.listProfs.length > 0" class="row">
              <!-- Instructor List -->
              <div v-for="(item, index) in storeProfs.listProfs" :key="index" class="col-lg-4 col-md-6 d-flex">
                <div class="instructor-box instruct-grid flex-fill">
                  <div class="instructor-img">
                    <router-link :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }">
                      <img style="height: 300px !important;width: auto !important;"
                        class="img-fluid"
                        alt=""
                        :src="item.photo === null ? '@/assets/img/user/user11.jpg' : item.photo"
                      />
                    </router-link>
                    <!-- <a href="javascript:;" class="rating-count"
                      ><i class="fa-regular fa-heart"></i
                    ></a> -->
                  </div>
                  <div class="instructor-content">
                    <h5><router-link :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }">{{ item.first_name }} {{ item.last_name.substring(0,1) }}</router-link></h5>
                    <div
                      class="rating-img d-flex align-items-center justify-content-center"
                    >
                      <img
                        src="@/assets/img/icon/play.svg"
                        class="me-1 "
                        alt=""
                      />
                      <p>{{ item.total_courses }}+ cours</p>
                    </div>
                      <div v-if="item.notes > 0" class="rating items-center">
                        <i v-for="items in item.notes" :key="items" class="fas fa-star filled me-1"></i>
                        <i v-for="items in (5 - item.notes)" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.notes }}</span>
                      </div>
                      <div v-else class="rating">
                        <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.notes }}</span
                        >
                      </div>
                  </div>
                </div>
              </div>
              <!-- /Instructor List -->
            </div>

            <div @click="storeProfs.loading === true ? page : page++" v-if="storeProfs.empty !== true" class="load-more text-center">
              <div v-if="storeProfs.loading === true" class="spinner-border" role="status">
                <span class="sr-only">Chargement...</span>
              </div>
              <span class="btn btn-primary" v-else>
                Voir plus
              </span>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="filter-clear">
              <div class="clear-filter d-flex align-items-center">
                <h4><i class="feather-filter"></i>Filtrer par catégorie</h4>
                <div class="clear-text">
                  <p></p>
                </div>
              </div>

              <!-- Search Filter -->
              <div class="card search-filter">
                <div class="card-body">
                  <div class="filter-widget mb-0">
                    <div class="categories-head d-flex align-items-center">
                      <h4>Course categories</h4>
                      <i class="fas fa-angle-down"></i>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Backend (3)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> CSS (2)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Frontend (2)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" checked />
                        <span class="checkmark"></span> General (2)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" checked />
                        <span class="checkmark"></span> IT & Software (2)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Photography (2)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Programming Language (3)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check mb-0">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Technology (2)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search Filter -->

              <!-- Search Filter -->
              <!-- <div class="card search-filter">
                <div class="card-body">
                  <div class="filter-widget mb-0">
                    <div class="categories-head d-flex align-items-center">
                      <h4>Instructors</h4>
                      <i class="fas fa-angle-down"></i>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Keny White (10)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> Hinata Hyuga (5)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check">
                        <input type="checkbox" name="select_specialist" />
                        <span class="checkmark"></span> John Doe (3)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check mb-0">
                        <input type="checkbox" name="select_specialist" checked />
                        <span class="checkmark"></span> Nicole Brown
                      </label>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- /Search Filter -->

              <!-- Search Filter -->
              <!-- <div class="card search-filter">
                <div class="card-body">
                  <div class="filter-widget mb-0">
                    <div class="categories-head d-flex align-items-center">
                      <h4>Price</h4>
                      <i class="fas fa-angle-down"></i>
                    </div>
                    <div>
                      <label class="custom_check custom_one">
                        <input type="radio" name="select_specialist" />
                        <span class="checkmark"></span> All (18)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check custom_one">
                        <input type="radio" name="select_specialist" />
                        <span class="checkmark"></span> Free (3)
                      </label>
                    </div>
                    <div>
                      <label class="custom_check custom_one mb-0">
                        <input type="radio" name="select_specialist" checked />
                        <span class="checkmark"></span> Paid (15)
                      </label>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- /Search Filter -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useProfStore } from '@stores/profs'
  export default {
    data() {
      return {
        storeProfs: useProfStore(),
        page: 1,
      };
    },
    watch: {
      async page(newValue) {
        await this.storeProfs.list(newValue)
      }
    },
    async mounted() {
      await this.storeProfs.list(this.page)
    },

    methods: {
      
    },
  };
</script>