<!-- eslint-disable vue/no-duplicate-attributes -->
<template>
  <!-- Header -->
  <header v-bind:class="$route.meta.headerClass">
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg scroll-sticky"
        v-bind:class="$route.meta.NavbarClass"
        :class="{ 'add-header-bg': isSticky }"
      >
        <div class="container">
          <div class="navbar-header">
            <!-- <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a> -->
            <router-link to="/" class="navbar-brand logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <div class="flex md:hidden">
              <div class="dropdown dropdown-bottom dropdown-end">
                <label
                  tabindex="0"
                  class="bg-white border-0 hover:text-base-200 btn m-1"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    ></path>
                  </svg>
                </label>
                <!-- si le user n'est pas conncté -->
                <ul
                  tabindex="0"
                  class="dropdown-content z-[1] menu p-2 shadow bg-white text-black rounded-box w-52"
                >
                  <li>
                    <router-link to="/professeurs">Nos professeurs</router-link>
                  </li>
                  <li>
                    <router-link to="/login">Connexion</router-link>
                  </li>
                  <li class="bg-black text-white rounded shadow" >
                    <router-link to="/register" class="text-white">S'inscrire</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <router-link to="/" class="menu-logo">
                <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              </router-link>
              <a
                id="menu_close"
                class="menu-close"
                href="javascript:void(0);"
                @click="closeSidebar"
              >
                <i class="fas fa-times"></i>
              </a>
            </div>
            <mainnav></mainnav>
          </div>
          <signpages></signpages>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
export default {
  data() {
    return {
      isSticky: false,
      isSidebarOpen: true,
    };
  },
  computed: {
    isHomethreeRoute() {
      return this.$route.path === "/index-three";
    },
    isHomefourRoute() {
      return this.$route.path === "/index-four";
    },
    // isHomeothers() {
    //   return this.$route.path === "/" || this.$route.path === "/index-two";
    // },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
