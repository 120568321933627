<template>
  <!-- Sidebar -->
  <div v-if="storeAuth.user !== null" class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget dash-profile">
        <div class="settings-menu p-0">
          <div class="profile-bg">
            <!-- <h5>Beginner</h5> -->
            <div class="bg-[#58BBDE] h-[130px]"></div>
            <!-- <img :src="storeAuth.user.banner" alt="" /> -->
            <div class="profile-img">
              <router-link to="instructor-profile"
                ><img :src="storeAuth.user.photo" alt=""
              /></router-link>
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4><router-link to="instructor-profile">{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name }}</router-link></h4>
              <p>Enseignant</p>
            </div>
            <div class="go-dashboard text-center">
              <router-link to="add-course" class="btn btn-primary"
                >Ajouter un cours</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <h3>DASHBOARD</h3>
          <ul>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-dashboard' ? 'active' : 'notactive'"
                to="instructor-dashboard"
                class="nav-link"
              >
                <i class="feather-home"></i> Dashboard
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-course' ? 'active' : 'notactive'"
                to="instructor-course"
                class="nav-link"
              >
                <i class="feather-book"></i> Mes cours
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-reviews' ? 'active' : 'notactive'"
                to="instructor-reviews"
                class="nav-link"
              >
                <i class="feather-star"></i>Mes Avis
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-payouts' ? 'active' : 'notactive'"
                to="instructor-payouts"
                class="nav-link"
              >
                <i class="feather-dollar-sign"></i>Mes reversements
              </router-link>
            </li>
          </ul>
          <div class="instructor-title">
            <h3>PARAMÈTRES DU COMPTE</h3>
          </div>
          <ul>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-edit-profile' ? 'active' : 'notactive'"
                to="instructor-edit-profile"
                class="nav-link"
              >
                <i class="feather-settings"></i> Modifier mon profil
              </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link
                :class="currentPath == 'instructor-security' ? 'active' : 'notactive'"
                to="instructor-security"
                class="nav-link"
              >
                <i class="feather-user"></i> Sécurité
              </router-link>
            </li> -->
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'instructor-social-profiles' ? 'active' : 'notactive'
                "
                to="instructor-social-profiles"
                class="nav-link"
              >
                <i class="feather-refresh-cw"></i> Profils sociaux
              </router-link>
            </li>
            <li class="nav-item">
              <a
                style="cursor: pointer;"
                :class="currentPath == 'index' ? 'active' : 'notactive'"
                @click="logOut"
                class="nav-link"
              >
                <i class="feather-power"></i> Se déconnecter
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore()
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  methods: {
    async logOut(){
      await this.storeAuth.logOut()
    }
  },
};
</script>
