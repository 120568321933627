<template>
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="settings-widget">
                  <div class="settings-inner-blk p-0">
                    <div class="sell-course-head comman-space">
                      <h3>Cours</h3>
                      <p>Gérez vos cours ainsi que leurs mises à jour, incluant les cours en direct, les brouillons et les analyses approfondies.</p>
                    </div>
                    <div class="comman-space pb-0">
                      <div class="instruct-search-blk">
                        <div class="show-filter choose-search-blk">
                          <form @submit.prevent="filterCourses"> 
                            <div class="row gx-2 align-items-center">
                              <div class="col-md-6 col-item">
                                <div class="search-group">
                                  <i class="feather-search"></i>
                                  <input v-model="searchTitle" type="text" class="form-control" placeholder="Rechercher un cours" />
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6 col-item">
                                <div class="form-group select-form mb-0">
                                  <vue-select v-model="searchStatus" :options="Choose" placeholder="Status" name="status" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="settings-tickets-blk course-instruct-blk table-responsive" v-if="filteredCourses.length >0">
                        <table class="table table-nowrap mb-2">
                          <thead>
                            <tr>
                              <th>Cours</th>
                              <th>Total étudiants</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in filteredCourses" :key="index">
                              <td>
                                <div class="sell-table-group d-flex align-items-center">
                                  <div class="sell-group-img">
                                    <router-link to="course-details">
                                      <img :src="item.image" style="width: 100%; height: 100%" class="img-fluid" alt="" />
                                    </router-link>
                                  </div>
                                  <div class="sell-tabel-info">
                                    <p>
                                      <router-link to="course-details">{{ item.title }}</router-link>
                                    </p>
                                    <div class="course-info d-flex align-items-center border-bottom-0 pb-0">
                                      <div class="rating-img d-flex align-items-center">
                                        <img src="@/assets/img/icon/icon-01.svg" alt="" />
                                        <p>{{ item.total_lessons }} Leçon(s)</p>
                                      </div>
                                      <div class="course-view d-flex align-items-center" v-if="item.duration !== ''">
                                        <img src="@/assets/img/icon/timer-start.svg" alt="" />
                                        <p>{{ item.duration }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{{ item.total_etudiant }}</td>
                              <td>
                                <span :class="{'badge info-low': item.status === 'Publié', 'badge info-inter': item.status !== 'Publié'}" class="pb-3">{{ item.status }}</span>
                              </td>
                              <td>
                                <div class="dropdown dropdown-end">
                                  <button tabindex="0" role="button" class="btn m-1 bt-square">
                                    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000" class="bi bi-three-dots-vertical h-6 w-6">
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                  </button>
                                  <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                    <li>
                                      <router-link :to="{ name: 'lessons', params: { slug: item.slug } }">Leçons</router-link>
                                    </li>
                                    <li>
                                      <router-link :to="{ name: 'add-course', params: { slug: item.slug } }">Modifier</router-link>
                                    </li>
                                    <li>
                                      <a style="cursor: pointer;" @click="deleteCours(item.id)" class="me-0">Supprimer</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else class="py-32 d-flex justify-content-center align-items-center">
                        Aucun cours trouvé
                      </div>
                      <dialog id="delete_modal" class="modall bg-transparent">
                        <div class="modal-box flex flex-col space-y-5">
                          <h3 class="font-bold text-md">Voulez-vous vraiment supprimer ce cours ?</h3>
                          <div class="modal-action">
                            <button @click="closeModal" class="btn bg-black text-white">Fermer</button>
                            <button :disabled="storeCourses.loading" @click="soumettre" class="btn bg-danger text-white">
                              <div v-if="storeCourses.loading === true" class="spinner-border" role="status">
                                <span class="sr-only">Chargement...</span>
                              </div>
                              <span v-else>Supprimer</span>
                            </button>
                          </div>
                        </div>
                      </dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <layouts1></layouts1>
  </div>
</template>

<script>
import { useCoursesStore } from '@stores/courses';
import { useAuthStore } from '@stores/auth'

export default {
  data() {
    return {
      storeCourses: useCoursesStore(),
      page: 1,
      id: '',
      storeAuth: useAuthStore(),
      Choose: ["Tout", "Brouillon", "Publié"],
      searchTitle: '',
      searchStatus: 'Tout',
    };
  },
  watch: {},
  async mounted() {
    await this.storeCourses.listProf();
  },
  computed: {
    filteredCourses() {
      const { listCoursesProfs } = this.storeCourses;
      return listCoursesProfs.filter((item) => {
        const nameMatch = item.title.toLowerCase().includes(this.searchTitle.toLowerCase());
        const statusMatch = this.searchStatus === 'Tout' || item.status === this.searchStatus;

        return nameMatch && statusMatch;
      });
    },
  },
  methods: {
    async filterCourses() {
      await this.storeCourses.listCoursesProfs();
    },

    deleteCours(id) {
      this.id = id;
      const dialog = document.querySelector("dialog");
      dialog.showModal();
    },

    async soumettre() {
      this.page = 1;
      await this.storeCourses.deleteCourses(this.id);
      await this.storeCourses.listProf(this.page);
      this.closeModal();
    },

    closeModal() {
      const dialog = document.querySelector("dialog");
      dialog.close();
    },
  },  
};
</script>
