import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useLessonsStore = defineStore('lessons', {
  state: () => ({ listLessons: null, lesson: null, coursLessons: [], progress: 0, loading: false }),
  actions: {
    list() {
      this.loading = true;
      axios.get(`/api/lessons`).then((response) => {
        this.listLessons = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    getCoursLessons(slug) {
      this.loading = true;
      axios.get(`/api/course-lessons-slug/${slug}`).then((response) => {
        this.coursLessons = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    get(id) {
      this.loading = true;
      axios.get(`/api/lessons/${id}`).then((response) => {
        this.lesson = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    watchLesson(body) {
      axios.post(`/api/watchLesson`, body).then((response) => {
        return response.data.message
      }).catch(() => {
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    addLessons(body) {
      this.loading = true;
      axios.post('/api/lessons', body, {
        onUploadProgress: (progressEvent) => {
          // Calculate the percentage of upload completion
          this.progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(`Upload Progress: ${this.progress}%`);
        },
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    updateLessons(body, id) {
      this.loading = true;
      axios.post(`/api/modifier-lessons/${id}`, body, {
        onUploadProgress: (progressEvent) => {
          // Calculate the percentage of upload completion
          this.progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(`Upload Progress: ${this.progress}%`);
        },
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    deleteLessons(id) {
      this.loading = true;
      axios.delete(`/api/lessons/${id}`).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})