<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
    <layoutsindex v-else></layoutsindex>
    <homebanner></homebanner>
    <!-- <featurecourse></featurecourse>
    <trendingcourse></trendingcourse>
    <shareknowledge></shareknowledge>
    <latestblog></latestblog> -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { router } from '@/router';
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
    };
  },
  mounted() {
    console.log(router)
  },
  name: "index",
};
</script>
