import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'
import { router } from '@/router';

export const useAuthStore = defineStore('auth', {
  state: () => ({ user: null, 
    prof: {
      photo: null,
      banner: null,
      facebook: '',
      youtube: '',
      instagram: '',
      twitter: '',
      description: '',
      categories: []
    }, loading: false, email: '', loadingPasse: false, loadingPhoto: false, error: false }),
  actions: {
    login(body) {
      this.loading = true;
      axios.post('/api/login', body).then(async r => {
        localStorage.setItem("access_token", r.data.access_token)
        this.Me("auth")
        // this.$router.push("/instructor-dashboard");
        toast.success("Bienvenu parmis nous.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        console.log(e);
        toast.error("Les informations d'identification sont incorrectes.", {
          autoClose: 3000,
        }); 
      })
    },
    confirmPayment(id) {
      axios.get(`/api/confirmPayment/${id}`).then((response) => {
        router.push("/mes-course");
        toast.success(response.data.message, {
          autoClose: 3000,
        });
      }).catch((e) => {
        router.push('/')
        toast.error(e.data.message, {
          autoClose: 3000,
        });
      })
    },
    Me(type = "") {
      axios.get('/api/me').then((response) => {
        this.user = response.data.data;
        console.log(router)
        if(type === "auth"){
          if (this.user.role === 'etudiant') {
            router.push("/setting-edit-profile");
          } else {
            router.push("/instructor-dashboard");
          }
        }
      }).catch(() => {
        this.error = true;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    register(body, type='student') {
      this.loading = true;
      axios.post('/api/register', body).then(async (r) => {
        localStorage.setItem("access_token", r.data.access_token)
       if(type !== 'student'){
        router.push("/register-step-one");
       } else{
        this.Me("auth")
       }
        // this.$router.push("/instructor-dashboard");
        toast.success("Bienvenu parmis nous.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "";
        if (error.response){
          // eslint-disable-next-line no-prototype-builtins
          if (error.response.data.data.hasOwnProperty("email")) {
            if (message === "") {
              message = error.response.data.data.email[0];
            } else {
              message += "\n" + error.response.data.data.email[0];
            }
          }
          // eslint-disable-next-line no-prototype-builtins
          if (error.response.data.data.hasOwnProperty("pseudo")) {
            if (message === "") {
              message = error.response.data.data.pseudo[0];
            } else {
              message += "\n" + error.response.data.data.pseudo[0];
            }
          }
        }
        
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    logOut() {
        axios.post('/api/logout', {}).then(() => {
          localStorage.clear();
          window.location.href = "/";
        }).catch(() => {
          console.error("Erreur de recuperation des informations du profile")
        })
    },
    isLogin(){
      if (localStorage.getItem("access_token") !== null) {
        return true;
      } else {
        return false;
      }
    },
    updatePhoto(body) {
      this.loadingPhoto = true;
      axios.post('/api/updatePhoto', body).then(async () => {
        this.Me("no auth")
        // this.$router.push("/instructor-dashboard");
        toast.success("Photo de profil modifiée avec succès.", {
            autoClose: 3000,
        }); 
        this.loadingPhoto = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        
        toast.error(message, {
          autoClose: 5000,
        });
        this.loadingPhoto = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    updateBanner(body) {
      axios.post('/api/updateBanner', body).then(async () => {
        // this.$router.push("/instructor-dashboard");
        toast.success("Bannière de profil modifiée avec succès.", {
            autoClose: 3000,
        }); 
        this.loadingPhoto = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        
        toast.error(message, {
          autoClose: 5000,
        });
        this.loadingPhoto = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    updateProfil(body, id) {
      this.loading = true;
      axios.put(`/api/users/${id}`, body).then(async () => {
        this.Me("no auth")
        // this.$router.push("/instructor-dashboard");
        toast.success("Profil modifiée avec succès.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    updateProf() {
      this.loading = true;
      const data = new FormData();
      data.append('photo', this.prof.photo)
      data.append('banner', this.prof.banner)
      if(this.prof.facebook !== ''){
        data.append('facebook', this.prof.facebook)
      }

      if(this.prof.youtube !== ''){
        data.append('youtube', this.prof.youtube)
      }

      if(this.prof.instagram !== ''){
        data.append('instagram', this.prof.instagram)
      }
      
      if(this.prof.twitter !== ''){
        data.append('twitter', this.prof.twitter)
      }

      data.append('description', this.prof.description)
      data.append('categories', JSON.stringify(this.prof.categories))
      axios.post('/api/updateProf', data).then(async () => {
        this.Me("auth")
        // this.$router.push("/instructor-dashboard");
        toast.success("Profil mises a jour avec succès.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    updatePassword(body) {
      this.loadingPasse = true;
      axios.post(`/api/changePassword`, body).then(async () => {
        this.logOut()
        // this.$router.push("/instructor-dashboard");
        toast.success("Mot de passe modifiée avec succès.", {
            autoClose: 3000,
        }); 
        this.loadingPasse = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        if (error.response) {
          message = error.response.data.message;
        }
        toast.error(message, {
          autoClose: 5000,
        });
        this.loadingPasse = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    contact(body) {
      this.loading = true;
      axios.post(`/api/contact`, body).then(async () => {
        // this.$router.push("/instructor-dashboard");
        toast.success("Nous vous remercions pour votre message. Nous y répondrons dans les plus brefs délais.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        if (error.response) {
          message = error.response.data.message;
        }
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    forgotPassword(body) {
      this.loading = true;
      axios.post(`/api/forgotPassword`, body).then(async () => {
        toast.success("Veuillez vérifier votre e-mail, car un code vous a été envoyé.", {
            autoClose: 3000,
        });
        router.push("/new-password"); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        if (error.response) {
          message = error.response.data.message;
        }
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
    updatePasswordCode(body) {
      this.loading = true;
      axios.post(`/api/updatePassword`, body).then(async () => {
        router.push("/login");
        toast.success("Votre mot de passe a été réinitialisé avec succès.", {
            autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        let message = "Nous avons rencontré un soucis actuellement";
        if (error.response) {
          message = error.response.data.message;
        }
        toast.error(message, {
          autoClose: 5000,
        });
        this.loading = false;
        // console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})