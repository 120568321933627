import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({ listNotifications: null, notification: null, loading: false }),
  actions: {
    list() {
        this.loading = true;
      axios.get(`/api/notifications`).then((response) => {
        this.listNotifications = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    get(id) {
      this.loading = true;
      axios.get(`/api/notifications/${id}`).then((response) => {
        this.notification = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})