<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <courseheader></courseheader>
    <!-- My Course -->
    <section class="course-content">
      <div class="container">
        <div class="student-widget">
          <div class="student-widget-group">
            <div class="row">
              <div class="col-lg-12" v-if="storeCourses.listCoursesStudents.length >0">
                <div class="showing-list">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="show-filter choose-search-blk">
                        <form action="#">
                          <div class="mycourse-student align-items-center">
                            <div class="student-search">
                              <div class="search-group">
                                <i class="feather-search"></i>
                                <input
                                  v-model="searchTitle"
                                  @input="applyFilters"
                                  type="text"
                                  name="title"
                                  class="form-control"
                                  placeholder="Rechercher un cours"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div v-for="(item, index) in filteredCourses" :key="index" class="col-xl-3 col-lg-4 col-md-6 d-flex">
                  <!-- <div v-for="(item, index) in storeCourses.listCoursesStudents" :key="index" class="col-xl-3 col-lg-4 col-md-6 d-flex"> -->
                    <div class="course-box course-design d-flex">
                      <div class="product">
                        <div class="product-img">
                          <router-link :to="'/course-details/'+item.slug">
                            <img
                              class="img-fluid"
                              alt=""
                              :src="item.image"
                            />
                          </router-link>
                        </div>
                        <div class="product-content">
                          <h3 class="title">
                            <router-link :to="'/course-details/'+item.slug"
                              >{{ item.title }}</router-link
                            >
                          </h3>
                          <div v-if="item.note > 0" class="rating">
                            <i v-for="items in item.note" :key="items" class="fas fa-star filled me-1"></i>
                            <i v-for="items in (5 - item.note)" :key="items" class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>{{ item.note }}</span> ({{ item.total_note }})</span
                            >
                          </div>
                          <div v-else class="rating">
                            <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              >{{ item.note }}</span
                            >
                          </div>
                          <div class="progress-stip">
                            <div
                              :style="{'width': item.user_progression}"
                              class="progress-bar bg-success progress-bar-striped active-stip"
                            ></div>
                          </div>
                          <div class="student-percent">
                            <p>{{ item.user_progression }}Terminé</p>
                          </div>
                          <div class="start-leason d-flex align-items-center">
                            <router-link :to="{ name: 'course-lesson', params: { slug: item.slug } }" class="btn btn-primary"
                              >{{ item.user_progression === '0%' ? 'Démarrer une leçon' : 'Continuer' }} </router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 d-flex align-items-center justify-content-center py-9" v-else>
                Vous n'avez encore payé aucun cours.
              </div>
            </div>
          </div>
        </div>
        {{ getFilteredCourses }}
      </div>
    </section>
    <!-- /My Course -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useCoursesStore } from '@stores/courses';

export default {
  components: {},
  data() {
    return {
      storeCourses: useCoursesStore(),
      page: 1,
      Out: ["4 Out of 5", "3 Out of 5", "2 Out of 5", "1 Out of 5"],
      searchTitle: '',
      filteredCourses: [], // Nouvelle propriété pour stocker les cours filtrés
    };
  },
  watch: {
    async searchTitle(newValue) {
      this.filteredCourses = this.filterCoursesByTitle(newValue);
    },
    async page(newValue) {
      await this.storeCourses.paidCoursesStudent(newValue);
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    getFilteredCourses() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filteredCourses = this.storeCourses.listCoursesStudents
    },
  },
  async mounted() {
    await this.storeCourses.paidCoursesStudent(this.page);
  },
  methods: {
    filterCoursesByTitle(title) {
      return this.storeCourses.listCoursesStudents.filter(course => course.title.toLowerCase().includes(title.toLowerCase()));
    },
    async applyFilters() {
      this.page = 1;
      this.filteredCourses = this.filterCoursesByTitle(this.searchTitle);
      await this.storeCourses.paidCoursesStudent(this.page);
    },
  },
};
</script>
