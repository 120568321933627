<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructorborder></layoutsinstructorborder>
    <depositbreadcrumb></depositbreadcrumb>
    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <!-- Profile Details -->
          <div class="col-xl-12 col-md-12">
            <div class="settings-widget">
              <div class="settings-inner-blk p-0">
                <div class="comman-space pb-0">
                  <div
                    class="filter-grp ticket-grp d-flex align-items-center justify-content-between"
                  >
                    <h3>Latest Transactions</h3>
                  </div>
                  <div class="settings-tickets-blk table-responsive">
                    <!-- Referred Users-->
                    <table class="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Referred ID</th>
                          <th>Details</th>
                          <th>Amount</th>
                          <th>Post Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><a href="javascript:;">K2WX7JJ6R1UA</a></td>
                          <td>10 USD Refunded from withdrawal rejection</td>
                          <td><span class="text-success">+ $45.00</span></td>
                          <td>$45.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">9RVE1N1TA1H3</a></td>
                          <td>Deposit Via Stripe Hosted - USD</td>
                          <td><span class="text-success">+ $75.00</span></td>
                          <td>$75.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">C83Z7EQ4A1WX</a></td>
                          <td>Deposit Via Stripe Hosted - USD</td>
                          <td><span class="text-success">+ $100.00</span></td>
                          <td>$100.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">1C6A5M4YPV39</a></td>
                          <td>Withdraw Via Bank withdrawal</td>
                          <td><span class="text-danger">- $5.00</span></td>
                          <td>$5.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">K2WX7JJ6R1UA</a></td>
                          <td>10 USD Refunded from withdrawal rejection</td>
                          <td><span class="text-danger">- $25.00</span></td>
                          <td>$25.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">KSM5CW4EOEGQ</a></td>
                          <td>Added Balance Via Admin</td>
                          <td><span class="text-success">+ $160.00</span></td>
                          <td>S160.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">K2WX7JJ6R1UA</a></td>
                          <td>10 USD Refunded from withdrawal rejection</td>
                          <td><span class="text-success">+ $150.00</span></td>
                          <td>$150.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">9RVE1N1TA1H3</a></td>
                          <td>Deposit Via Stripe Hosted - USD</td>
                          <td><span class="text-success">+ $45.00</span></td>
                          <td>$45.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">C83Z7EQ4A1WX</a></td>
                          <td>Deposit Via Stripe Hosted - USD</td>
                          <td><span class="text-success">+ $10.00</span></td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td><a href="javascript:;">1C6A5M4YPV39</a></td>
                          <td>Withdraw Via Bank withdrawal</td>
                          <td><span class="text-danger">- $10.00</span></td>
                          <td>$10.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- /Referred Users-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
