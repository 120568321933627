<template>
  <!-- Header -->
  <header v-if="storeAuth.user" class="header header-page">
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg header-nav scroll-sticky"
        :class="{ 'add-header-bg': isSticky }"
      >
        <div class="container">
          <div class="navbar-header">
            <!-- <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a> -->
            <router-link to="/" class="navbar-brand logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <router-link to="/" class="menu-logo">
                <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              </router-link>
              <a
                id="menu_close"
                class="menu-close"
                href="javascript:void(0);"
                v-show="isSidebarOpen"
                @click="closeSidebar"
              >
                <i class="fas fa-times"></i>
              </a>
            </div>
            <mainnav></mainnav>
          </div>
          <ul v-if="storeAuth.isLogin() === true && storeAuth.user !== null" class="nav header-navbar-rht">
            <!-- <li class="nav-item">
              <router-link class="" to="/register-prof" v-if="storeAuth.user.role !== 'professeur'">Donner des cours</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="" to="/course">Cours</router-link>
            </li>
            <li class="nav-item">
              <router-link class="" to="/instructor-list">Nos professeurs</router-link>
            </li>
            <li class="nav-item">
              <router-link class="" to="/blog-masonry">Blog</router-link>
            </li>
          </ul>
          <signpages v-if="storeAuth.user === null"></signpages>
          <headerpage1></headerpage1>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      isSticky: false,
      isSidebarOpen: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
