<template>
  <!-- Home Banner -->
  <section class="home-slide d-flex align-items-center" style="min-height: 100vh;margin-top: 7%;">
    <div class="container">
      <div class="row ">
        <div class="col-md-6">
          <div class="home-slide-face aos" data-aos="fade-up">
            <div class="home-slide-text flex flex-col items-center text-center lg:items-start lg:text-left ">
              <h5>Put music in your life</h5>
              <h1 class="text-black lg:text-left">Journey Through Melodies and Emotions!</h1>
              <p>Trouvez le cours de musique parfait pour vous</p>
              <div class="container">
                <div v-if="storeAuth.isLogin() !== true && storeAuth.user === null" class="row space-y-2 lg:space-y-0">
                  <router-link class="col-md-4 lg:mx-2 text-center nav-link text-white rounded py-1 px-4" to="login" style="background-color: rgb(88 187 222 )">S'inscrire</router-link>
                  <router-link class="col-md-4 text-center nav-link border border-black font-gray rounded py-1 px-4" to="/register-prof" >Donner des cours</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <div class="girl-slide-img aos" data-aos="fade-up">
            <img id="hero-banner" src="https://themusichall.fr/assets/hero.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
  <div class="home pt-9 3xl:pt-[4%]">
    <section
      class="bg-black py-[75px] lg:py-[140px] trigger flex flex-col items-center justify-center"
    >
      <div class="lg:max-w-7xl">
        <div
          class="flex items-center justify-between lg:px-40 3xl:px-0 px-3 space-y-5 lg:space-y-0 lg:space-x-3 lg:flex-row flex-col"
        >
          <div
            data-aos="slide-up"
            class="lg:w-1/2 text-center aos-init aos-animate"
          >
            <h3
              class="font-bold text-white text-2xl md:text-4xl text-white lg:text-left md:leading-tight"
            >
              Apprenez avec les meilleurs <br />
              enseignants
            </h3>
          </div>
          <div
            data-aos="slide-up"
            class="lg:w-1/2 lg:mt-4 text-center aos-init aos-animate"
          >
            <p class="text-white lg:pl-0 pl-2 pr-2 md:text-xl lg:text-left">
              Profitez de cours interactifs, apprenez la musique à votre rythme.
              Démarrez votre aventure musicale maintenant.
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-6 space-y-9 md:space-y-0 p-10 lg:px-40 3xl:px-0 px-3"
        >
          <div
            v-for="(item, index) in storeProf.topProfs"
            :key="index"
            data-aos="slide-up"
            data-aos-delay="0"
            data-aos-duration="900"
            class="flex flex-col cursor-pointer space-y-2 animated-content items-center md:items-start aos-init aos-animate"
          >
            <img
              :src="item.photo"
              alt=""
              class="w-[319px] h-[435px] rounded-xl"
            />
            <h3
              class="font-bold text-white text-3xl mb-3 text-white md:leading-normal"
            >
              {{ item.first_name }} {{ item.last_name }}
            </h3>
            <p class="text-xl md:text-2xl text-white md:pl-0">{{ item.pseudo }}</p>
            <p class="text-white md:pl-0 lg:text-lg min-h-[40px] max-h-[40px]">
              {{ item.short_description }}
            </p>
            <div class="3xl:pt-9">
              <router-link class="text-white w-auto text-md bg-[#58BBDE] border-0 px-8 py-2 focus:outline-none rounded transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }" >En savoir plus</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section
      class="py-[75px] lg:py-[140px] bg-gradient-to-b to-50% from-[#8ADBFE] to-white py-9 trigger flex justify-center"
    >
      <div class="lg:max-w-7xl">
        <div
          data-aos="slide-up"
          class="pt-10 lg:px-40 3xl:px-0 px-3 space-y-4 text-center flex-col items-center aos-init aos-animate"
        >
          <h3
            class="mt-5 text-center font-bold text-black text-2xl md:text-5xl mb-3 md:leading-normal"
          >
            Jamais il n'a été aussi facile d'apprendre.
          </h3>
          <p class="md:text-2xl text-black">
            Profitez de cours interactifs, apprenez la musique à votre rythme.
            Démarrez votre aventure musicale maintenant.
          </p>
        </div>
        <div
          class="mt-8 grid grid-cols-1 lg:grid-cols-2 lg:gap-9 space-y-9 lg:space-y-0 mx-auto p-10 lg:px-40 3xl:px-0 px-3"
        >
          <div
            data-aos="slide-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            class="flex justify-center lg:justify-end animated-content aos-init"
          >
            <div
              class="md:w-[90%] rounded-xl md:p-9 p-3 flex flex-col space-y-4 bg-[#FDF2E6]"
            >
              <img src="https://themusichall.fr/assets/edu1.png" alt="" />
              <h3
                class="font-bold text-black text-2xl md:text-3xl mb-3 text-black md:leading-normal"
              >
                Trouvez votre instrument
              </h3>
              <p class="md:text-xl text-black">
                Parcourez tous les profils. Choisissez votre super prof selon ce
                que vous voulez (prix, qualifications, commentaires, évaluations).
              </p>
            </div>
          </div>
          <div
            data-aos="slide-up"
            data-aos-delay="250"
            data-aos-duration="1000"
            class="flex justify-center lg:justify-start animated-content aos-init"
          >
            <div
              class="md:w-[90%] rounded-xl md:p-9 p-3 flex flex-col space-y-4 bg-[#D7E9E8]"
            >
              <img src="https://themusichall.fr/assets/edu2.png" alt="" />
              <h3
                class="font-bold text-black text-2xl md:text-3xl mb-3 text-black md:leading-normal"
              >
                Apprenez d’où vous voulez
              </h3>
              <p class="md:text-xl text-black">
                Tout ce dont vous avez besoin c’est un ordinateur, une tablette ou
                même juste de votre téléphone mobile et vous êtes prêt à commencer
                votre cours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section
      class="py-[75px] lg:py-[140px] md:py-9 items-center lg:relative bg-center bg-cover bg-no-repeat h-[350px]"
    >
      <img
        src="https://themusichall.fr/assets/bg_start.png"
        class="w-full h-[300px] md:h-auto aos-init aos-animate"
        alt=""
        data-aos="slide-up"
        data-aos-delay="200"
        data-aos-duration="1200"
      />
      <div
        data-aos="fade-in"
        data-aos-duration="1500"
        data-aos-delay="1100"
        style=""
        class="-mt-[65%] md:-mt-[42%] lg:mt-9 3xl:mt-0 m-auto lg:absolute lg:inset-40 xl:inset-[250px] 3xl:inset-[300px] my-auto lg:px-8 space-y-6 text-center flex-col items-center aos-init aos-animate"
      >
        <h3
          class="mt-5 text-center font-bold text-white xs:text-2xl md:text-3xl xl:text-4xl 3xl:text-6xl mb-3 md:leading-normal"
        >
          Commencez à jouer
        </h3>
        <p
          class="md:text-lg xl:text-3xl 3xl:text-4xl text-white 3xl:leading-normal"
        >
          Accédez instantanément à votre cours et avancez <br />
          à apprendre à votre propre rythme
        </p>
      <router-link class="3xl:text-2xl mt-3 inline-flex mb-10 text-white hover:bg-black bg-[#58BBDE] border-0 px-8 py-3 font-bold focus:outline-none hover:bg-[#58BBDE] rounded text-md xl:text-xl transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300" to="course">Trouver votre cours</router-link>
      </div>
    </section>
  
    <section
      class="md:-mt-10 bg-top bg-white lg:h-[230px] xl:h-[400px] 3xl:h-[650px] rounded-t-[200%]"
    ></section>
  
    <section class="bg-white py-[10%] flex justify-center">
      <div class="flex flex-col 3xl:max-w-7xl lg:px-40 3xl:px-0 px-3">
        <div
          data-aos="fade-in"
          class="lg:pt-10 px-2 lg:px-8 space-y-4 text-center flex-col items-center aos-init"
        >
          <h3
            class="mt-5 text-center font-extrabold text-black xs:text-xl md:text-5xl 3xl:text-5xl mb-3 md:leading-normal"
          >
            Découvrez nos masterclass.
          </h3>
          <p class="md:text-2xl 3xl:text-2xl text-black">
            Retrouvez nos meilleurs professeurs dans une collection de cours
            spéciaux <br />
            et à durée limités, ne les manquez surtout pas !
          </p>
        </div>
        <div
          class="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6   gap-3 md:gap-0 mx-auto"
        >
          <div
            v-for="(item, index) in storeCategorie.courseCategorie.slice(0, 6)"
            :key="index"
            data-aos="fade-in"
            data-aos-delay="0"
            data-aos-duration="900"
            class="flex flex-col lg:space-y-5 items-center aos-init"
          >
            <div
              class="avatar transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
            >
              <div class="w-16 md:w-32">
                <div
                  class="bg-gradient-to-t from-[#0054C7] to-[#50AAC8] p-3 md:p-7 rounded-full"
                >
                  <img
                    :src="item.image"
                    alt=""
                    class="w-30"
                  />
                </div>
              </div>
            </div>
            <p
              class="mt-3 text-center font-bold text-black text-xl md:text-3xl mb-3 md:leading-normal"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </section>
  
    <section class="md:pt-9">
      <div
        class="pb-[75px] lg:pb-[140px] md:mt-10 bg-top bg-center bg-cover h-100 pt-10 pb-[5%] lg:px-8 space-y-9 text-center flex-col items-center"
        style="
          background-image: url('https://themusichall.fr/assets/bg-player.svg');
        "
      >
        <div
          data-aos="fade-in"
          class="w-full flex items-center justify-center lg:-mt-9 aos-init"
        >
          <img
            src="https://themusichall.fr/assets/player.svg"
            alt=""
            class="w-[40%]"
          />
        </div>
        <h3
          data-aos="slide-up"
          class="mt-5 text-center font-bold text-white text-2xl md:text-4xl mb-3 md:leading-normal aos-init"
        >
          Vous êtes un(e) Super Prof ?
        </h3>
        <p data-aos="slide-up" class="md:text-2xl text-white aos-init">
          Rejoignez THE MUSIC HALL <br />
          et partagez votre passion et votre talent pour la musique <br />
          avec des milliers d'élèves dans le monde entier.
        </p>
        <a
          v-if="storeAuth.isLogin() !== true && storeAuth.user === null"
          href="/register"
          class="mt-3 inline-flex mb-10 text-white hover:bg-black bg-[#58BBDE] hover:text-[#58BBDE] border border-[#58BBDE] border-0 px-8 py-3 font-bold focus:outline-none hover:bg-[#58BBDE] rounded text-lg transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300 aos-init"
          data-aos="slide-up"
        >
          Inscrivez-vous dès aujourd'hui
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import dashboard from "@/assets/json/dashboard.json";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useAuthStore } from '@stores/auth'
import { useProfStore } from '@stores/profs'
import { useCategorieStore } from '@stores/categorie'
export default {
  components: {
  },
  data() {
    return {
      storeAuth: useAuthStore(),
      storeProf: useProfStore(),
      storeCategorie: useCategorieStore(),
      Category: ["Category", "Angular", "Node Js", "React", "Python"],
      dashboard: dashboard,
    };
  },
  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/course-list");
    },
  },
  async mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    await this.storeProf.topProf()
    await this.storeCategorie.courseCategories()
  },
};
</script>
