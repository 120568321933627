<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!-- Page Wrapper -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Instructor Dashboard -->
          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="tak-instruct-group">
              <div class="row">
                <div class="col-md-12">
                  <div class="settings-widget">
                    <div class="settings-inner-blk p-0" >
                      <div class="comman-space pb-0">
                        <div class="sell-course-head withdraw-history-head border-bottom-0">
                          <h3>Mes demandes de reversements</h3>
                          <button
                            onclick="add_modal.showModal()"
                            style="background-color: #58BBDE;"
                            class="btn bg-[#58BBDE] text-white mb-4"
                          >
                            Faire une demande.
                          </button>
                        </div>
                        <div class="instruct-search-blk mb-0">
                          <div class="show-filter all-select-blk">
                            <form @submit.prevent="filterReversements">
                              <div class="row gx-2 align-items-center">
                                <div class="col-md-6 col-lg-3 col-item">
                                  <div class="form-group select-form mb-0">
                                    <vue-select v-model="selectedStatus" :options="Transaction" placeholder="Statut" />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="comman-space pb-0" v-if="filteredReversements.length>0">
                        <div class="settings-referral-blk course-instruct-blk table-responsive">
                          <!-- Instructor Users-->
                          <table class="table table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Date</th>
                                <th>Statut</th>
                                <th>Montant</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in filteredReversements" :key="index">
                                <td>#{{ item.id }}</td>
                                <td>{{ item.date }}</td>
                                <td>
                                  <span :class="getStatusClass(item.status)" class="pb-3">{{ item.status }}</span>
                                </td>
                                <td>{{ item.montant }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <dialog id="add_modal" class="modall bg-transparent">
                            <div class="modal-box flex flex-col space-y-5">
                              <h3 class="font-bold text-lg">Nouvelle demande de reversement</h3>
                              <input
                                type="number"
                                v-model="montant"
                                class="input input-bordered w-full"
                                name="montant"
                                id="montant"
                                placeholder="Entrer votre montant"
                              />
                              <div class="modal-action">
                                <button onclick="add_modal.close()" class="btn bg-black text-white">
                                  Fermer
                                </button>
                                <button
                                  v-if="montant !== '' && montant > 0"
                                  @click="soumettre"
                                  style="background-color: #58BBDE;"
                                  class="btn bg-[#58BBDE] text-white"
                                >
                                    <div v-if="storeReversements.loadingAdd === true" class="spinner-border" role="status">
                                        <span class="sr-only">Chargement...</span>
                                    </div>
              
                                  <span v-else>Envoyer</span>
                                </button>
                              </div>
                            </div>
                          </dialog>
                        </div>
                      </div>
                      <div v-else class="py-32 d-flex justify-content-center align-items-center">
                        Aucune demande trouvé
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Instructor Dashboard -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useReversementStore } from '@stores/reversements';

export default {
  data() {
    return {
      storeReversements: useReversementStore(),
      Transaction: ["Tout", "Accepté", "Refusé", "En attente"],
      selectedStatus: "Tout",
      montant: 0,
      page: 1,
    };
  },
  computed: {
    hasReversements() {
      return this.storeReversements.profReversements.length > 0;
    },
    filteredReversements() {
      if (this.selectedStatus === "Tout") {
        return this.storeReversements.profReversements;
      } else {
        return this.storeReversements.profReversements.filter(
          (item) => item.status === this.selectedStatus
        );
      }
    },
  },
  methods: {
    getStatusClass(status) {
      return {
        "badge info-high": status === "En cours",
        "badge info-low": status === "Payé",
        "badge info-medium": status !== "En cours" && status !== "Payé",
      };
    },
    async filterReversements() {
      await this.storeReversements.listReversementProf();
    },
    async soumettre(){
      const dialog = document.getElementById("add_modal");
      await this.storeReversements.addReversementProf({montant: parseInt(this.montant)})
      dialog.close();
    }
  },
  async mounted() {
    await this.storeReversements.listReversementProf();
  },
};
</script>
