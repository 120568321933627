<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <!-- <registerbanner></registerbanner> -->

      <div class="login-wrap-bg">
        <!-- Login -->
        <div class="container">
          <div class="login-wrapper">
            <div class="loginbox register-box">
              <div class="img-logo">
                <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
                <div class="back-home">
                  <router-link to="login">Retourner à l'accueil</router-link>
                </div>
              </div>
              <h1>Finalisez votre profil enseignant</h1>
              <div class="row">
                <div class="col-lg-5">
                  <div class="profile-box">
                    <div class="circle-bar circle-bar1 text-center">
                      <div class="circle-graph1" data-percent="30">
                        <circle-progress :percent="30" />
                        <p>30% <span>2 de 3</span></p>
                      </div>
                    </div>
                    <h3>Complément de profil</h3>
                    <div class="personal-detail d-flex align-items-center">
                      <span>1</span>
                      <div class="personal-text">
                        <h4>Catégories et Profil</h4>
                        <p class="mb-0">Configurer votre profil et choisissez votre catégorie</p>
                      </div>
                    </div>
                    <div class="personal-detail d-flex align-items-center">
                      <span class="active-color active-bar">2</span>
                      <div class="personal-text">
                        <h4>Profils sociaux</h4>
                        <p class="mb-0">Configurer les liens de vos profils sociaux</p>
                      </div>
                    </div>
                    <div class="personal-detail d-flex align-items-center">
                      <span>3</span>
                      <div class="personal-text">
                        <h4>Biographie</h4>
                        <p class="mb-0">Entrez votre biographie ici</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="personal-form">
                    <h4>Profils sociaux</h4>
                    <form @submit.prevent="submitForm">
                      <div class="form-group">
                        <label class="form-control-label">X Twiter </label>
                        <input
                          type="text"
                          required
                          v-model="storeAuth.prof.twitter"
                          class="form-control"
                          placeholder="URL du profil X"
                        />
                      </div>
                      <div class="form-group">
                        <label class="form-control-label">Facebook</label>
                        <input
                          type="text"
                          required
                          v-model="storeAuth.prof.facebook"
                          class="form-control"
                          placeholder="URL du profil Facebook"
                        />
                      </div>
                      <div class="form-group">
                        <label class="form-control-label">Instagram</label>
                        <input
                          type="text"
                          required
                          v-model="storeAuth.prof.instagram"
                          class="form-control"
                          placeholder="URL du profil Instagram"
                        />
                      </div> 
                      <div class="form-group">
                        <label class="form-control-label">YouTube</label>
                        <input required v-model="storeAuth.prof.youtube" type="text" class="form-control" placeholder="URL Youtube" />
                      </div>
                      <div class="btn-group d-flex">
                        <div class="back-btn">
                          <router-link
                            to="register-step-one"
                            class="btn btn-back"
                            type="submit"
                            >Retour</router-link
                          >
                        </div>
                        <div class="next-btn">
                          <button class="btn btn-primary btn-start">Suivant</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { useAuthStore } from '@stores/auth'
export default {
  components: {
    CircleProgress,
  },
  data() {
    return {
      storeAuth: useAuthStore(),
    };
  },
  computed: {
    isCheck(){
      if(this.storeAuth.prof.facebook === '' || this.storeAuth.prof.youtube === '' || this.storeAuth.prof.instagram === '' || this.storeAuth.prof.twitter === ''){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/register-step-three");
    },
  },
};
</script>
