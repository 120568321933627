<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructorborder></layoutsinstructorborder>
    <instructorlist></instructorlist>
    <!-- Page Wrapper -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="showing-list">
              <div class="row">
                <div class="col-lg-6">
                  <div class="d-flex align-items-center">
                    <div class="view-icons">
                      <router-link
                        :class="currentPath == 'instructor-list' ? 'active' : 'notactive'"
                        to="instructor-list"
                        class="list-view"
                        ><i class="feather-list"></i
                      ></router-link>
                    </div>
                    <div class="show-result">
                      <h4>Affichage de 1 à 9 sur 50 résultats</h4>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="show-filter add-course-info">
                    <form action="#">
                      <div class="row gx-2 align-items-center">
                        <div class="col-md-6 col-item">
                          <div class="search-group">
                            <i class="feather-search"></i>
                            <input
                              v-model="searchQuery"
                              @input="applyFilters"
                              type="text"
                              class="form-control"
                              name="name"
                              placeholder="Rechercher un professeur"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-item">
                          <div class="form-group select-form mb-0">
                            <vue-select v-model="selectedNote" @input="applyFilters" :options="published" placeholder="Note" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div v-if="storeProfs.listProfs.length > 0" class="row"> -->
            <div v-if="filteredProfessors.length > 0" class="row">
              <!-- Instructor List -->
              <!-- <div v-for="(item, index) in storeProfs.listProfs" :key="index" class="col-lg-6 d-flex"> -->
              <div v-for="(item, index) in filteredProfessors" :key="index" class="col-lg-6 d-flex">
                <div class="instructor-list flex-fill">
                  <div class="instructor-img">
                    <router-link :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }">
                      <img
                        class="img-fluid  w-[300px] h-[300px"
                        alt=""
                        :src="item.photo === null ? '@/assets/img/user/user11.jpg' : item.photo"
                      />
                    </router-link>
                  </div>
                  <div class="instructor-content">
                    <h5><router-link :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }">{{ item.first_name }} {{ item.last_name.substring(0,1) }}</router-link></h5>
                    <!-- <h6>Enseignant</h6> -->
                    <div class="instructor-info">
                      <div class="rating-img d-flex align-items-center">
                        <img
                          src="@/assets/img/icon/play.svg"
                          class="me-1"
                          alt=""
                        />
                        <p>{{ item.total_courses }}+ cours</p>
                      </div>
                      <div class="rating-img d-flex align-items-center">
                        <img
                          src="@/assets/img/icon/user-icon.svg"
                          class="me-1]"
                          alt=""
                        />
                        <p>{{ item.total_etudiant }} Etudiant</p>
                      </div>
                      <div v-if="item.notes > 0" class="rating">
                        <i v-for="items in item.notes" :key="items" class="fas fa-star filled me-1"></i>
                        <i v-for="items in (5 - item.notes)" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.notes }}</span>
                      </div>
                      <div v-else class="rating">
                        <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.notes }}</span
                        >
                      </div>
                    
                    </div>
                    <div class="instructor-badge">
                      <span v-for="(items, index) in item.categories" :key="index" :class="index + 1 === item.categories.length ? 'web-badge ' : 'web-badge me-1 '">
                        <img
                          :src="items.icon"
                          class="me-1"
                          alt=""
                        />
                        {{ items.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Instructor List -->
            </div>

            <div @click="storeProfs.loading === true ? page : page++" v-if="storeProfs.empty !== true" class="load-more text-center">
              <div v-if="storeProfs.loading === true" class="spinner-border" role="status">
                <span class="sr-only">Chargement...</span>
              </div>
              <span class="btn btn-primary" v-else>
                Voir plus
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<!-- <script>
  import { useProfStore } from '@stores/profs'
  export default {
    data() {
      return {
        storeProfs: useProfStore(),
        published: ["1", "2", "3", "4","5"],
        page: 1,
      };
    },
    watch: {
      async page(newValue) {
        await this.storeProfs.list(newValue)
      }
    },
    async mounted() {
      await this.storeProfs.list(this.page)
    },

    methods: {
      
    },
  };
</script> -->

<script>
  import { useProfStore } from '@stores/profs'
  
  export default {
    data() {
      return {
        storeProfs: useProfStore(),
        published: ["1", "2", "3", "4", "5"],
        page: 1,
        searchQuery: "",
        selectedNote: null,
      };
    },
    computed: {
      filteredProfessors() {
        const { listProfs } = this.storeProfs;
        return listProfs.filter(prof => {
          const nameMatches = 
            prof.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            prof.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            prof.pseudo.toLowerCase().includes(this.searchQuery.toLowerCase());

          const noteMatches = this.selectedNote === null || prof.notes == this.selectedNote;
          return nameMatches && noteMatches;
        });
      },
    },
    watch: {
      async page(newValue) {
        await this.storeProfs.list(newValue);
      },
    },
    async mounted() {
      await this.storeProfs.list(this.page);
    },
  };
</script>