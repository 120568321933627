<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="img-logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <a href="javascript:void(0);">Retour à l'accueil.</a>
              </div>
            </div>
            <h1>Configurer un nouveau mot de passe.</h1>
            <form @submit.prevent="soumettre" method="POST">
              <div class="form-group">
                <label class="form-control-label">Code</label>
                <input
                  type="text"
                  class="form-control pass-input"
                  required
                  placeholder="Entrez le code"
                  v-model="user.code"
                />
              </div>
              <div class="form-group">
                <label class="form-control-label">Mot de passe</label>
                <div class="pass-group" ref="passwordGroup">
                  <input
                    ref="passwordInput"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input"
                    required
                    placeholder="Enter your password"
                    :class="{ 'password-error': validationError }"
                    v-model="user.password"
                    @input="handlePasswordChange"
                  />
                  <span
                    class="toggle-password feather-eye"
                    @click="togglePassword"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                  <span class="pass-checked" :class="{ active: isPasswordSecure }">
                    <i class="feather-check"></i>
                  </span>
                </div>
                <div
                  id="passwordStrength"
                  style="display: flex"
                  :class="[
                    'password-strength',
                    strength === 'poor' ? 'poor-active' : '',
                    strength === 'weak' ? 'avg-active' : '',
                    strength === 'strong' ? 'strong-active' : '',
                    strength === 'heavy' ? 'heavy-active' : '',
                  ]"
                >
                  <span
                    id="poor"
                    class="active"
                    :class="{ active: strength === 'poor' }"
                  ></span>
                  <span
                    id="weak"
                    class="active"
                    :class="{ active: strength === 'weak' }"
                  ></span>
                  <span
                    id="strong"
                    class="active"
                    :class="{ active: strength === 'strong' }"
                  ></span>
                  <span
                    id="heavy"
                    class="active"
                    :class="{ active: strength === 'heavy' }"
                  ></span>
                </div>
                <div id="passwordInfo">
                  <span v-if="validationError === 1"></span>
                  <span v-else-if="validationError === 2" style="color: red"
                    >😠 Faible. Doit contenir au moins 8 caractères.</span
                  >
                  <span v-else-if="validationError === 3" style="color: orange"
                    >😲 Moyen. Doit contenir au moins 1 lettre ou chiffre.</span
                  >
                  <span v-else-if="validationError === 4" style="color: blue"
                    >🙂 Presque. Doit contenir un symbole spécial.</span
                  >
                  <span v-else-if="validationError === 5" style="color: green"
                    >😊 Génial ! Vous avez un mot de passe sécurisé.</span
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Confirmer le mot de passe</label>
                <div class="pass-group" ref="passwordGroup1">
                  <input
                    ref="passwordInput1"
                    :type="showPassword1 ? 'text' : 'password'"
                    class="form-control pass-input"
                    placeholder="Enter your password"
                    required
                    :class="{ 'password-error1': validationError1 }"
                    v-model="password1"
                    @input="handlePasswordChange1"
                  />
                  <span
                    class="toggle-password feather-eye"
                    @click="togglePassword1"
                    :class="{
                      'feather-eye': showPassword1,
                      'feather-eye-off': !showPassword1,
                    }"
                  ></span>
                  <span class="pass-checked" :class="{ active: isPasswordSecure1 }">
                    <i class="feather-check"></i>
                  </span>
                </div>
                <div
                  id="passwordStrength"
                  style="display: flex"
                  :class="[
                    'password-strength',
                    strength1 === 'poor' ? 'poor-active' : '',
                    strength1 === 'weak' ? 'avg-active' : '',
                    strength1 === 'strong' ? 'strong-active' : '',
                    strength1 === 'heavy' ? 'heavy-active' : '',
                  ]"
                >
                  <span
                    id="poor"
                    class="active"
                    :class="{ active: strength1 === 'poor' }"
                  ></span>
                  <span
                    id="weak"
                    class="active"
                    :class="{ active: strength1 === 'weak' }"
                  ></span>
                  <span
                    id="strong"
                    class="active"
                    :class="{ active: strength1 === 'strong' }"
                  ></span>
                  <span
                    id="heavy"
                    class="active"
                    :class="{ active: strength1 === 'heavy' }"
                  ></span>
                </div>
                <div id="passwordInfo">
                  <span v-if="validationError1 === 1"></span>
                  <span v-else-if="validationError1 === 2" style="color: red"
                    >😠 Faible. Doit contenir au moins 8 caractères.</span
                  >
                  <span v-else-if="validationError1 === 3" style="color: orange"
                    >😲 Moyen. Doit contenir au moins 1 lettre ou chiffre.</span
                  >
                  <span v-else-if="validationError1 === 4" style="color: blue"
                    >🙂 Presque. Doit contenir un symbole spécial.</span
                  >
                  <span v-else-if="validationError1 === 5" style="color: green"
                    >😊 Génial ! Vous avez un mot de passe sécurisé.</span
                  >
                </div>
                <div v-if="isConfirm === false" class="alert alert-danger d-flex align-items-center" role="alert">
                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                  <div>
                    Confirmer le mot de passe est different du mot de passe initialement entrer.
                  </div>
                </div>
              </div>
              <!-- <div class="form-check remember-me">
                <label class="form-check-label mb-0">
                  <input class="form-check-input" type="checkbox" name="remember" /> I
                  agree to the <a href="javascript:void(0);">Terms of Service</a> and
                  <a href="javascript:void(0);">Privacy Policy.</a>
                </label>
              </div> -->
              <div class="d-grid">
                <button class="btn btn-primary btn-start" type="submit">
                  <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span v-else>
                    Réinitialiser le mot de passe.
                  </span>
                </button>
              </div>
              <div class="reset-account">
                <p class="mb-0">
                  Vous avez déjà un compte ? <router-link to="/login">Connectez-vous</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { router } from '@/router';
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      showPassword: true,
      validationError: 0,
      isPasswordValid: false,
      strength: "",
      showPassword1: true,
      password1: "",
      validationError1: 0,
      isPasswordValid1: false,
      strength1: "",
      user: {
        password: "",
        code: "",
      }
    };
  },
  computed: {
    isPasswordSecure() {
      return this.strength === "heavy" && this.validationError === 5;
    },
    isPasswordSecure1() {
      return this.strength1 === "heavy" && this.validationError1 === 5;
    },
    isConfirm(){
      if (this.password1 !== this.user.password) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async soumettre(){
      if (this.isConfirm === true) {
        await this.storeAuth.updatePasswordCode(this.user)
      }
      
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
      const passwordInput = this.$refs.passwordInput;
      passwordInput.type = this.showPassword ? "text" : "password";
    },
    togglePassword1() {
      this.showPassword1 = !this.showPassword1;
      const passwordInput1 = this.$refs.passwordInput1;
      passwordInput1.type = this.showPassword1 ? "text" : "password";
    },
    handlePasswordChange() {
      const newPassword = this.user.password;
      this.validatePassword(newPassword);
    },
    handlePasswordChange1() {
      const newPassword1 = this.password1;
      this.validatePassword1(newPassword1);
    },
    validatePassword(value) {
      this.validationError = 0;
      if (!value) {
        this.validationError = 0;
      } else if (value.length < 8) {
        this.validationError = 2;
      } else if (!/[a-zA-Z]/.test(value) && !/[0-9]/.test(value)) {
        this.validationError = 3;
      } else if (!/[!@#$%^&*()]/.test(value)) {
        this.validationError = 4;
      } else {
        this.validationError = 5;
      }
      this.strength = this.strengthColor(this.validationError);
    },
    validatePassword1(value) {
      this.validationError1 = 0;
      if (!value) {
        this.validationError1 = 0;
      } else if (value.length < 8) {
        this.validationError1 = 2;
      } else if (!/[a-zA-Z]/.test(value) && !/[0-9]/.test(value)) {
        this.validationError1 = 3;
      } else if (!/[!@#$%^&*()]/.test(value)) {
        this.validationError1 = 4;
      } else {
        this.validationError1 = 5;
      }
      this.strength1 = this.strengthColor1(this.validationError1);
    },
    strengthColor(count) {
      if (count < 1) return "";
      if (count < 2) return "poor";
      if (count < 3) return "weak";
      if (count < 4) return "strong";
      return "heavy";
    },
    strengthColor1(count) {
      if (count < 1) return "";
      if (count < 2) return "poor";
      if (count < 3) return "weak";
      if (count < 4) return "strong";
      return "heavy";
    },
    // hasNumber(value) {
    //   return /[0-9]/.test(value);
    // },
    // hasMixed(value) {
    //   return /[a-z]/.test(value) && /[A-Z]/.test(value);
    // },
    // hasSpecial(value) {
    //   return /[!#@$%^&*)(+=._-]/.test(value);
    // },
    // strengthIndicator(value) {
    //   let strengths = 0;

    //   if (value.length >= 8) strengths = 1;
    //   if (this.hasNumber(value) && value.length >= 8) strengths = 2;
    //   if (this.hasSpecial(value) && value.length >= 8 && this.hasNumber(value))
    //     strengths = 3;
    //   if (
    //     this.hasMixed(value) &&
    //     this.hasSpecial(value) &&
    //     value.length >= 8 &&
    //     this.hasNumber(value)
    //   )
    //     strengths = 3;
    //   return strengths;
    // },
  },
  mounted() {
    if(this.storeAuth.email === ''){
      router.push("/forgot-password");
    }
    // Call the validatePassword method here
    this.user.email = this.storeAuth.email
    this.validatePassword(this.user.password);
    this.validatePassword1(this.password1);
  },
};
</script>
