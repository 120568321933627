<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
		<layouts2 v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layouts2>
        <layoutsloginborder v-else></layoutsloginborder>
       
            <!-- Course Lesson -->
			<section class="page-content course-sec course-lesson">
				<div class="container">
				
					<div v-if="storeCourses.course !== null" class="row">
						<div class="col-lg-8">
						
						<!-- Introduction -->
						<div v-if="videoChange !== null" class="student-widget lesson-introduction">
							<div class="lesson-widget-group">
								<h4 class="tittle">{{ titleChange }}</h4>
								<video
								style="width: 100%;"
								class="video-thumbnail" 
								data-fancybox=""
								:src="videoChange"
								controls
								controlsList="nodownload"
								></video>
 
							</div>
						</div>
						
						<!-- /Introduction -->
					</div>	
						<div class="col-lg-4">

							<!-- Course Lesson -->
							<div class="lesson-group">
								<div class="course-card">
									<h6 class="cou-title">
										<a class="" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true">Voir le(s) leçon(s) <span>{{ storeCourses.course.total_lessons }} Leçon(s)</span> </a>

									</h6>
									<div id="collapseOne" class="card-collapse collapse show" style="">
										<div class="modal-action mb-4">
											<button
												@click="prev"
												class="btn bg-black text-white mr-6"
											>
												Leçon précédente
											</button>
											<button
												@click="next"
												style="background-color: #58BBDE;"
												class="btn bg-[#58BBDE] text-white"
											>
												Leçon suivante
											</button>
										</div>
										<div class="progress-stip">
											<div :style="{'width': `${progressionChange}%`}" class="progress-bar bg-success progress-bar-striped active-stip"></div>
										</div>
										<div class="student-percent lesson-percent">
											<p>{{ storeCourses.course.duration }}<span>{{ progressionChange }}%</span></p>
										</div>
										<ul>
											<li @click="watchLessons(item, index)" style="cursor: pointer;" v-for="(item, index) in storeLessons.coursLessons" :key="index">
												<p :class="lu === item.id ? 'play-intro' : ''">{{ item.title }}</p>
												<div>
													<img v-if="item.user_start === true" src="@/assets/img/icon/play-icon.svg" alt="" >
													<img v-else src="@/assets/img/icon/lock.svg" alt="" >
												</div>
											</li>
										</ul>
									</div>
									<dialog id="add_modal" class="modall bg-transparent">
										<div class="modal-box flex flex-col space-y-5">
											<h3 class="font-bold text-lg">Noter ce cours</h3>
											<form @submit.prevent="saveCommentaire">
												<div class="form-group rating">
													<i v-for="star in stars" :key="star" style="cursor: pointer;" @click="rate(star)" 
													:class="{ 'fas fa-star filled me-1': star <= getRating, 'fas fa-star me-1': star > getRating }"></i>
												</div>
													
												<div class="form-group">
													<textarea
													rows="4"
													v-model="commentaire"
													required
													class="form-control"
													placeholder="Commentaire"
													></textarea>
												</div>
												<div class="submit-section">
													<button class="btn submit-btn" type="submit">
													<div v-if="storeNotes.loadingAdd === true" class="spinner-border" role="status">
														<span class="sr-only">Chargement...</span>
													</div>
													<span v-else>
														Envoyer
													</span>
													</button>
												</div>
											</form>
										</div>
									</dialog>
								</div>
							</div>
							<!-- /Course Lesson -->
							
						</div>	
						
						
					</div>	
				</div>
			</section>
			<!-- /Course Lesson -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
import { useNoteStore } from '@stores/notes'
import { useLessonsStore } from '@stores/lessons'
import { useCoursesStore } from '@stores/courses'
import { router } from '@/router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {},
  data() {
    return {
    storeAuth: useAuthStore(),
		storeLessons: useLessonsStore(),
		storeCourses: useCoursesStore(),
		storeNotes: useNoteStore(),
		commentaire: "",
        isComment: false,
        currentRating: 0,
        maxRating: 5,
		progression: 0,
		video: null,
		title: "",
		lu: 0,
		index: 0,
		countStart: 0,
    };
  },

  computed: {
	stars: function() {
          return Array.from({ length: this.maxRating }, (_, index) => index + 1);
	},
	getRating: {
		get(){
			return this.currentRating
		},
		set(value){
			this.currentRating = value
		}
	},
	videoChange: {
		get(){
			return this.video
		},
		set(value) {
			this.video = value;
		}
	},
	titleChange: {
		get(){
			return this.title
		},
		set(value) {
			this.title = value;
		}
	},
	progressionChange: {
		get(){
			return this.progression
		},
		set(value) {
			this.progression = value;
		}
	},
	countChange: {
		get(){
			return this.countStart
		},
		set(value) {
			this.countStart = value;
		}
	},
	// eslint-disable-next-line vue/return-in-computed-property
	getFirstLesson(){
		if(this.storeLessons.coursLessons.length > 0 && this.index === 0){
			if(this.storeLessons.coursLessons.length == 1){
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.isComment = true;
			}
			console.log(this.storeLessons.coursLessons)
			this.watchLessons(this.storeLessons.coursLessons[0])
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.countChange = this.storeLessons.coursLessons.filter(
			(item) => item.user_start === true
			).length;
			if(this.countChange > 0){
				this.CalculeProgression();
			}
			
		}
	}
  },

  async mounted() {
	await this.storeCourses.get(router.currentRoute.value.params.slug);
	this.isComment = await this.storeNotes.checkNote({
		course_id: this.storeCourses.course.id,
	});
  },
  async beforeMount() {
	await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
  },
  methods: {
	rate(rating) {
        console.log(rating)
        this.getRating = rating;
        console.log(this.getRating)
	},
	async saveCommentaire(){
		try {
			await this.storeNotes.addNoteCourses({
			commentaire: this.commentaire,
			note: this.currentRating,
			course_id: this.storeCourses.course.id
			}, router.currentRoute.value.params.slug)
			this.isComment = true;
			const dialog = document.getElementById("add_modal");
			dialog.close();
		} catch (e) {
			toast.error("Nous avons reçu une erreur pendant la soumission de votre commentaire")
			console.log(e)
		}
	},
	next(){
		if(this.index + 1 < this.storeLessons.coursLessons.length){
			this.index++
		}
		
		this.watchLessons(this.storeLessons.coursLessons[this.index], this.index)
	},
	prev(){
		if(this.index > 0){
			this.index--
		}
		this.watchLessons(this.storeLessons.coursLessons[this.index], this.index)
	},
	async watchLessons(lesson, index){
		console.log(this.lesson)
		if (lesson.user_start === false) {
			await this.storeLessons.watchLesson({
				lesson_id: lesson.id,
			})
			this.storeLessons.coursLessons[index].user_start = true
			this.countChange = this.countChange + 1;
			this.CalculeProgression()
		}
		this.videoChange = lesson.video;
		this.titleChange = lesson.title;
		this.lu = lesson.id;
		if(this.index + 1 === this.storeLessons.coursLessons.length && this.isComment === false){
			const dialog = document.getElementById("add_modal");
			dialog.showModal();
		}
	},
	CalculeProgression(){
		this.progressionChange = Math.trunc((this.countChange / this.storeLessons.coursLessons.length) * 100)
	}
  },
};
</script>