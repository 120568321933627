import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useProfStore = defineStore('prof', {
  state: () => ({ listProfs: [], topProfs: [], prof: null, loading: false, empty: false }),
  actions: {
    list(page) {
      this.loading = true;
      axios.get(`/api/profs`).then((response) => {
        if (page === 1) {
          this.listProfs = response.data.data;
        } else {
          this.listProfs = [...this.listProfs, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    get(pseudo) {
      this.loading = true;
      axios.get(`/api/profs/${pseudo}`).then((response) => {
        this.prof = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    topProf() {
      this.loading = true;
      axios.get(`/api/topProfs`).then((response) => {
        this.topProfs = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})