<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layouts2 v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layouts2>
		<layoutsloginborder v-else></layoutsloginborder>
            <supportbreadcrumb></supportbreadcrumb>
            <!-- Help Details -->
			<div class="page-content">
				
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-8 mx-auto">
							<div class="support-wrap">
								<form @submit.prevent="soumettre" method="post">
									<div class="form-group">
										<label>Nom</label>
										<input required v-model="user.name" type="text" class="form-control" placeholder="votre nom">
									</div>
									<div class="form-group">
										<label>Email</label>
										<input required v-model="user.email" type="email" class="form-control" placeholder="votre email">
									</div>
									<div class="form-group">
										<label>Message</label>
										<textarea required v-model="user.message" class="form-control" placeholder="votre message" rows="4"></textarea>
									</div>
									<button type="submit" class="btn btn-submit">
										<div v-if="storeAuth.loading === true" class="spinner-border" role="status">
											<span class="sr-only">Chargement...</span>
										</div>
										<span v-else>
											Envoyer
										</span>
									</button>
								</form>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<!-- /Help Details -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
export default {
  components: {},
  data() {
    return {
      storeAuth: useAuthStore(),
	user:{
		email: '',
		name: '',
		message: ''
	}
    };
  },

  methods: {
	async soumettre(){
		await this.storeAuth.contact(this.user)
		this.user={
			email: '',
			name: '',
			message: ''
		}
	}
  },
};
</script>