<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!-- Page Wrapper -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Instructor Dashboard -->
          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="settings-widget">
                  <div class="settings-inner-blk p-0">
                    <div class="sell-course-head comman-space">
                      <h3>Mes avis</h3>
                    </div>
                    <div class="comman-space pb-0">
                      <div class="instruct-search-blk mb-0">
                        <div class="show-filter all-select-blk">
                          <form @submit.prevent="filterReviews">
                            <div class="row gx-2 align-items-center">
                              <div class="col-md-6 col-lg-3 col-item">
                                <div class="search-group">
                                  <i class="feather-search"></i>
                                  <input
                                    v-model="searchQuery"
                                    type="text"
                                    class="form-control"
                                    placeholder="Rechercher un de vos élèves"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-3 col-item">
                                <div class="form-group select-form mb-1">
                                  <vue-select v-model="selectedRating" :options="Rating" placeholder="Rating" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div  v-if="filteredReviews.length>0">
                      <div v-for="(item, index) in filteredReviews" :key="index" class="comman-space bdr-bottom-line">
                        <!-- Review Content -->
                        <div class="instruct-review-blk">
                          <div class="review-item">
                            <div class="instructor-wrap border-0 m-0">
                              <div class="about-instructor">
                                <div class="abt-instructor-img">
                                  <router-link to="instructor-profile"
                                    ><img
                                      :src="item.author_img"
                                      alt="img"
                                      class="img-fluid"
                                  /></router-link>
                                </div>
                                <div class="instructor-detail">
                                  <h5>
                                    <router-link to="instructor-profile"
                                      >{{ item.author_first_name }} {{ item.author_last_name }}</router-link
                                    >
                                  </h5>
                                  <!-- <p>UX/UI Designer</p> -->
                                </div>
                              </div>
                              <div v-if="item.note > 0" class="rating">
                                <i v-for="items in item.note" :key="items" class="fas fa-star filled me-1"></i>
                                <i v-for="items in (5 - item.note)" :key="items" class="fas fa-star me-1"></i>
                              </div>
                            </div>
                            <p class="rev-info">
                              “ {{ item.commentaire }} “
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="py-32 d-flex align-items-center justify-content-center">
                      Aucun avis trouvé
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Instructor Dashboard -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
import { useNoteStore } from '@stores/notes'

export default {
  data() {
    return {
      storeNotes: useNoteStore(),
      storeAuth: useAuthStore(),
      Rating: ["Tout", "1", "2", "3", "4", "5"],
      searchQuery: '',
      selectedRating: 'Tout',
    };
  },
  computed: {
    filteredReviews() {
      const { profNotes } = this.storeNotes;

      return profNotes.filter((item) => {
        const nameMatch =
          item.author_first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          item.author_last_name.toLowerCase().includes(this.searchQuery.toLowerCase());

        const ratingMatch =
          this.selectedRating === 'Tout' || String(item.note) === this.selectedRating;

        return nameMatch && ratingMatch;
      });
    },
  },
  methods: {
    async filterReviews() {
      await this.storeNotes.listNoteProf(this.storeAuth.user.pseudo);
    },
  },
  async mounted() {
    await this.storeNotes.listNoteProf(this.storeAuth.user.pseudo);
  },
};
</script>
