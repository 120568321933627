import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
  state: () => ({ listCart: [], cart: 0, totalPrice: 0, loading: false }),
  actions: {
    listCartCourse() {
      this.loading = true;
      axios.get(`/api/cart`).then((response) => {
        this.listCart = response.data.data;
        this.totalPrice = this.listCart.reduce((accumulator, currentItem) => accumulator + currentItem.course_price, 0);
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile ",e)
      })
    },
    addCart(body) {
      this.loading = true;
      axios.post(`/api/cart-addItem`, body).then((e) => {
        this.listCartCourse()
        console.log(e)
        toast.success(e.data.message, {
          autoClose: 3000,
        }); 
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile ",e)
      })
    },
    deleteByCart(body) {
      this.loading = true;
      axios.post(`/api/cart-deleteItem`, body).then(() => {
        this.listCartCourse()
        toast.success("Cours supprimé avec succès", {
          autoClose: 3000,
      }); 
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile ",e)
      })
    },
    deleteAllCart(body) {
      this.loading = true;
      axios.post(`/api/cart-deleteAll`, body).then(() => {
        this.listCartCourse()
        toast.success("Votre panier a été vider avec succès", {
          autoClose: 3000,
      }); 
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile ",e)
      })
    },
    payCourses() {
      this.loading = true;
      axios.post(`/api/payCourse`, {}).then((response) => {
        window.open(`${response.data.lien}`);
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})