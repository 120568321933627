<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <courseheader></courseheader>
    <!-- Purchase History -->
    <section class="course-content purchase-widget">
      <div class="container">
        <div class="student-widget">
          <div class="student-widget-group">
            <div class="row">
              <div class="col-lg-12" v-if="storeCourses.listPaiementCourses.length >0">
                <div class="row">
                  <div v-for="(item, index) in storeCourses.listPaiementCourses" :key="index" class="col-lg-12 col-md-12 d-flex">
                    <div class="course-box course-design list-course d-flex">
                      <div class="product">
                        <div class="product-img">
                          <router-link to="course-details">
                            <img
                              class="img-fluid"
                              alt=""
                              :src="item.course.image"
                            />
                          </router-link>
                          <div class="price">
                            <h3 v-if="item.course.price !== 0">{{ item.course.price }} <span v-if="item.course.old_price !==0 && item.course.old_price !==null">{{ item.course.old_price }}</span></h3>
                            <h3 v-else class="free-color">GRATUIT</h3>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="head-course-title">
                            <h3 class="title">
                              <router-link to="course-details"
                                >{{ item.course.title }}</router-link
                              >
                            </h3>
                            <div class="all-btn all-category d-flex align-items-center">
                              <router-link to="view-invoice" class="btn btn-primary"
                                >Invoice</router-link
                              >
                            </div>
                          </div>
                          <div
                            class="course-info d-flex align-items-center border-bottom-0 pb-0"
                          >
                            <div class="rating-img d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-01.svg" alt="" />
                              <p>{{ item.course.total_lessons }}+ Leçon(s)</p>
                            </div>
                            <div class="course-view d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-02.svg" alt="" />
                              <p>{{ item.course.duration }}</p>
                            </div>
                          </div>
                          <div v-if="item.course.note > 0" class="rating">
                            <i v-for="items in item.course.note" :key="items" class="fas fa-star filled me-1"></i>
                            <i v-for="items in (5 - item.course.note)" :key="items" class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>{{ item.course.note }}</span> ({{ item.course.total_note }})</span
                            >
                          </div>
                          <div v-else class="rating">
                            <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>{{ item.course.note }}</span> ({{ item.course.total_note }})</span
                            >
                          </div>
                          <!-- <div class="rating">
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>4.0</span> (15)</span
                            >
                          </div> -->
                          <div class="course-group d-flex mb-0">
                            <div class="course-group-img d-flex">
                              <router-link to="student-profile"
                                ><img
                                  :src="item.course.prof.photo"
                                  alt=""
                                  class="img-fluid"
                              /></router-link>
                              <div class="course-name">
                                <h4>
                                  <router-link to="student-profile"
                                    >{{ item.course.prof.first_name }} {{ item.course.prof.last_name }}</router-link
                                  >
                                </h4>
                                <p>Enseignant</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div @click="storeCourses.loading === true ? page : page++" v-if="storeCourses.empty !== true" class="load-more text-center">
                  <div v-if="storeCourses.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span class="btn btn-primary" v-else>
                    Voir plus
                  </span>
                </div>
              </div>
              <div class="col-lg-12 d-flex align-items-center justify-content-center py-9" v-else>
                Vous n'avez encore payé aucun cours.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Purchase History -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useCoursesStore } from '@stores/courses'
export default {
  data() {
    return {
      storeCourses: useCoursesStore(),
      page: 1,
    };
  },
  watch: {
    async page(newValue) {
      await this.storeCourses.listUserPaiement(newValue)
    }
  },
  async mounted() {
    if(this.storeCourses.listPaiementCourses.length === 0){
      await this.storeCourses.listUserPaiement(this.page);
    }
  },
};
</script>