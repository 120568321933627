<template>
    <div class="main-wrapper">
      <layouts></layouts>
      <section class="my-12 px-2 md:px-[10%]">
        <div v-if="storeCours.course !== null" class="mb-5">
          <div class="flex justify-center">
            <div class="text-center md:max-w-xl lg:max-w-3xl">
              <h2 class="mb-12 px-6 text-3xl font-bold">
                Leçons du cours : {{ storeCours.course.title }}
              </h2>
              <!-- Laurent note :  add_modal peut rester comme ça  -->
              <button
                class="mb-12 btn border-black border bg-white"
                onclick="add_modal.showModal()"
                >
                <!-- @click="addModalForm" -->
                <i class="fa fa-plus"></i> Leçons
              </button>
              <dialog id="add_modal" class="modall bg-transparent">
                <div class="modal-box flex flex-col space-y-5">
                  <h3 class="font-bold text-lg">Nouvelle leçon</h3>
                  <input
                    type="text"
                    v-model="title"
                    class="input input-bordered w-full"
                    name="titre"
                    id="titre"
                    placeholder="Titre de la leçon"
                  />
                  <input
                    type="file"
                    @change="uploadVideo"
                    ref="fileInput"
                    accept="video/*"
                    class="file-input file-input-bordered w-full"
                  />
                  <div
                    v-if="storeLessons.loading === true"
                    class="flex justify-between items-center space-x-9"
                  >
                    <div class="w-full h-4 mb-4 bg-gray-200 rounded-full">
                      <div
                        class="h-4 bg-[#58BBDE] rounded-full"
                        :style="{ width: `${storeLessons.progress}%` }"
                      ></div>
                    </div>
                    <span
                      class="text-md font-bold text-black mb-3 md:leading-normal"
                      >{{ storeLessons.progress }}%</span
                    >
                  </div>
                  <div class="modal-action">
                    <button @click="closeAdd" class="btn bg-black text-white">
                      Fermer
                    </button>
                    <button
                      :disabled="storeLessons.loading"
                      @click="soumettre"
                      style="background-color: #58BBDE;"
                      class="btn bg-[#58BBDE] text-white"
                    >
                        <div v-if="storeLessons.loading === true" class="spinner-border" role="status">
                            <span class="sr-only">Chargement...</span>
                        </div>
  
                      <span v-else>Enregitrer</span>
                    </button>
                  </div>
                </div>
              </dialog>
            </div>
          </div> 
          <div class="bg-white text-dark rounded-b-2xl px-40">
            <div v-if="storeCours.course !== null" class="grid grid-cols-1 gap-3 flex justify-center ">
              <!-- lesson -->
              <div
                v-for="(item, index) in storeLessons.coursLessons"
                :key="index"
                class="rounded border p-3 flex justify-between"
              >
                <h3 class="text-lg">Leçon {{ index + 1 }} : {{ item.title }}</h3>
                <span class="flex space-x-2 items-center">
                  <!-- Laurent note --important-- : ici , il faut completer l'id de la lesson pour que ça donne edit_modal_3 par exemple.. 
                on aura donc edit_modal_1 , edit_modal_2 , .... , edit_modal_n pour le boutton et le dialog
              -->
                  <button href="" @click="videoModal(item.video)">
                    <i class="fa fa-eye"></i>
                  </button>
                  <button href="" @click="showModalForm(index, item.id)">
                    <i class="fa fa-edit"></i>
                  </button>
                  <dialog :id="`edit_modal_${index}`" class="modall bg-transparent">
                    <div class="modal-box flex flex-col space-y-5">
                      <h3 class="font-bold text-lg">Modifier la leçon</h3>
                      <input
                        type="text"
                        v-model="title"
                        class="input input-bordered w-full"
                        name="titre"
                        id="titre"
                        placeholder="Titre de la leçon"
                      />
                      <input
                        type="file"
                        @change="uploadVideo"
                        accept="video/*"
                        class="file-input file-input-bordered w-full"
                      />
                      <div class="mt-4" v-if="progress > 0">
                        <div class="progress-stip">
                            <div :style="{'width': `${progress}%`}" class="progress-bar bg-success progress-bar-striped active-stip"></div>
                        </div>
                        <!-- <div class="bg-gray-200 h-4 rounded-lg">
                          <div
                            :style="{ width: progress + '%' }"
                            class="bg-blue-500 h-full rounded-lg"
                          ></div>
                        </div> -->
                        <p class="mt-2">{{ Math.round(progress) }}%</p>
                      </div>
                      <div class="modal-action">
                        <button
                          @click="closeUpdate"
                          class="btn bg-black text-white"
                        >
                          Fermer
                        </button>
                        <button
                          :disabled="storeLessons.loading"
                          @click="update"
                          style="background-color: #58BBDE;"
                          class="btn bg-[#58BBDE] text-white"
                        >
                            <div v-if="storeLessons.loading === true" class="spinner-border" role="status">
                                <span class="sr-only">Chargement...</span>
                            </div>
  
                            <span v-else>Modifier</span>
                        </button>
                      </div>
                    </div>
                  </dialog>
                  <button @click="deleteLessons(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <dialog id="modal_video" class="modall bg-transparent">
            <div class="modal-box flex flex-col space-y-5">
              <h3 class="font-bold text-lg">Video de la leçon</h3>
              <video
                id="my-video"
                class="rounded-2xl w-[100%]"
                :src="videoUrl"
                controls
              ></video>
              <div class="modal-action">
                <button @click="closeVideo" class="btn bg-black text-white">
                  Fermer
                </button>
              </div>
            </div>
          </dialog>
          <div
            v-if="storeLessons.coursLessons.length === 0"
            class="flex flex-col items-center h-screen"
          >
            <!-- <img
              src="@/assets/empty.png"
              alt="Image"
              class="max-w-full max-h-full"
            /> -->
            <p class=" mt-4">
              Aucune leçon disponible pour ce cour
            </p>
          </div>
        </div>
      </section>
      <layouts1></layouts1>
    </div>
</template>

<script>
import { router } from '@/router';
import { useLessonsStore } from '@stores/lessons'
import { useCoursesStore } from '@stores/courses'
export default {
    data() {
        return {
            storeLessons : useLessonsStore(),
            storeCours : useCoursesStore(),
            title : "",
            video : "",
            videoUrl : "",
            fileInput : "",
            index : 0,
            uploading : false,
            progress : 0,
            id : 0,
        }
    },

    async mounted() {
        document.title = "Leçons - The Music Hall";
        await this.storeCours.get(router.currentRoute.value.params.slug);
        await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
    },

    methods: {
      async soumettre() {
        const dialog = document.getElementById("add_modal");
        if (this.title !== "" && this.video !== "") {
          const data = new FormData();
          data.append("title", this.title);
          data.append("video", this.video);
          data.append("course_id", this.storeCours.course.id);

          await this.storeLessons.addLessons(data, this.id);
          await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
          await dialog.close(); 
        }
      },

        videoModal(video) {
            const dialog = document.getElementById("modal_video");
            this.videoUrl = video;
            dialog.showModal();
        },
        closeVideo(){
            const dialog = document.getElementById("modal_video");
            this.videoUrl = "";
            dialog.close();
        },
        async update() {
            const dialog = document.getElementById(`edit_modal_${this.index}`);
            if (this.title !== "") {
                const data = new FormData();
                data.append("title", this.title);
                if (this.video !== "") data.append("video", this.video);

                data.append("course_id", this.storeCours.course.id);
                await this.storeLessons.updateLessons(data, this.id);
                await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
                this.title = ""
                dialog.close();
            }
        },
        closeAdd() {
            const dialog = document.getElementById("add_modal");
            this.storeLessons.loading = false;
            dialog.close();
        },
        closeUpdate() {
            const dialog = document.getElementById(`edit_modal_${this.index}`);
            this.storeLessons.loading = false;
            this.storeLessons.progress = false;
            dialog.close();
        },
        uploadVideo(event) {
            this.video = event.target.files[0];
        },
        showModalForm(indice, key) {
            console.log(indice)
            this.index = indice;
            this.id = key;
            const dialog = document.getElementById(`edit_modal_${indice}`);
            this.title = this.storeLessons.coursLessons[indice].title;
            dialog.showModal();
        },
        addModalForm() {
            const dialog = document.getElementById("add_modal");
            this.title = "";
            this.video = "";
            dialog.showModal();
        },
        async deleteLessons(id) {
            if (
                confirm(
                    "Êtes vous sûr de vouloir supprimer cette leçon ? (Cette action sera irréversible!)"
                )
            ) {
                await this.storeLessons.deleteLessons(id);
                await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
            }
        }
    },
}
</script>

<style></style>
