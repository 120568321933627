<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="img-logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <router-link to="/">Retourner à l'accueil</router-link>
              </div>
            </div>
            <h1>Mot de passe oublié ?</h1> 
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-group">
                <label class="form-control-label">Email</label>
                <Field
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                />
              </div>
              <div class="d-grid">
                <button class="btn btn-start" type="submit">
                  <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span v-else>
                    Réinitialiser
                  </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      emailError: "",
    };
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  methods: {
    // submitForm() {
    //   this.$router.push("/login");
    // },
    async onSubmit(values) {
      this.storeAuth.email = values.email
      await this.storeAuth.forgotPassword({email: values.email})
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
    });
    return {
      schema,
    };
  },
};
</script>
