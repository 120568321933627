import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useBlogStore = defineStore('blog', {
  state: () => ({ listBlog: [], blog: null, similars: [], loading: false, empty: false }),
  actions: {
    list(page) {
        this.loading = true;
      axios.get(`/api/articles?page=${page}`).then((response) => {
        if (page === 1) {
          this.listBlog = response.data.data
        } else {
          this.listBlog = [...this.listBlog, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    get(slug) {
        this.loading = true;
      axios.get(`/api/articles/${slug}`).then((response) => {
        this.blogSimilaire(slug)
        this.blog = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    blogSimilaire(slug) {
      axios.get(`/api/articleSimilar/${slug}`).then((response) => {
        this.similars = response.data.data;
      }).catch(() => {
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})