<template>
  <!-- Inner Banner -->
  <!-- <div :style="{'background-image': 'url(' + storeCourses.course.image + ') !important' }" class="inner-banner"> -->
  <div class="inner-banner bg-[#58BBDE]">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="instructor-wrap border-bottom-0 m-0">
            <div class="about-instructor align-items-center">
              <div class="abt-instructor-img">
                <router-link to="instructor-profile"
                  ><img
                    :src="storeCourses.course.prof.photo !== null ? storeCourses.course.prof.photo : '@/assets/img/user/user1.jpg'"
                    alt="img"
                    class="img-fluid"
                /></router-link>
              </div>
              <div class="instructor-detail me-3">
                <h5><router-link :to="'/instructor-profile/'+ storeCourses.course.prof.pseudo" >{{ storeCourses.course.prof.first_name }} {{ storeCourses.course.prof.last_name }}</router-link></h5>
                <!-- <p>UX/UI Designer</p> -->
              </div>
              <div v-if="storeCourses.course.note > 0" class="rating mb-0">
                <i v-for="items in storeCourses.course.note" :key="items" class="fas fa-star filled me-1"></i>
                <i v-for="items in (5 - storeCourses.course.note)" :key="items" class="fas fa-star me-1"></i>
                <span class="d-inline-block average-rating"
                  ><span>{{ storeCourses.course.note }}</span> ({{ storeCourses.course.total_note }})</span
                >
              </div>
              <div v-else class="rating mb-0">
                <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                <span class="d-inline-block average-rating"
                  ><span>{{ storeCourses.course.note }}</span> ({{ storeCourses.course.total_note }})</span
                >
              </div>
            </div>
            <span class="web-badge mb-3">{{ storeCourses.course.categorie }}</span>
          </div>
          <h2>{{ storeCourses.course.title }}</h2>
          <!-- <p>
            Learn Web Development by building 25 websites and mobile apps using HTML, CSS,
            Javascript, PHP, Python, MySQL & more!
          </p> -->
          <div class="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
            <div class="cou-info">
              <img src="@/assets/img/icon/icon-01.svg" alt="" />
              <p>{{ storeCourses.course.total_lessons }} Leçon(s)</p>
            </div>
            <div class="cou-info">
              <img src="@/assets/img/icon/timer-icon.svg" alt="" />
              <p>{{ storeCourses.course.duration }}</p>
            </div>
            <div class="cou-info">
              <img src="@/assets/img/icon/people.svg" alt="" />
              <p>{{ storeCourses.course.total_etudiant }} Étudiants inscrits</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Inner Banner -->
</template>

<script>
  import { useCoursesStore } from '@stores/courses'
  export default {
    data() {
      return {
        storeCourses: useCoursesStore(),
      };
    },
  };
</script>