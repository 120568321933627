<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
	<layouts2 v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layouts2>
	<layoutsloginborder v-else></layoutsloginborder>
       <notificationsbreadcrumb></notificationsbreadcrumb>
            <!-- Notification -->
			<section class="course-content">
				<div class="container">
					
					<div class="title-sec">
						<div class="row">
							<div class="col-sm-6 col-lg-5">
								<h2>Notifications</h2>
								<p>Improve the way your work, discover a brand new tool and drop the hassle once and for all.</p>
							</div>	
							<div class="col-sm-6 col-lg-7">
								<div class="text-end">
									<a href="javascript:;" class="btn btn-mark">Mark All as Read</a>
								</div>
							</div>	
						</div>	
					</div>	
					
					<!-- Notifications List -->					
					<div class="notify-sec">
						<div class="row">
							<div class="col-md-12">
								<h5>Today</h5>
								<div class="notify-item">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<router-link to="instructor-profile">
													<img class="avatar-img semirounded-circle" src="@/assets/img/user/user2.jpg" alt="User Image">
												</router-link>
												<div class="notify-detail">
													<h6><router-link to="instructor-profile">Rolands R </router-link><span>Today at 9:42 AM</span></h6>
													<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="text-md-end">
												<a href="javascript:;" class="btn">Mark as Read</a>
											</div>
										</div>
									</div>
								</div>
								
								<div class="notify-item">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<router-link to="instructor-profile">
													<img class="avatar-img semirounded-circle" src="@/assets/img/user/user1.jpg" alt="User Image">
												</router-link>
												<div class="notify-detail">
													<h6><router-link to="instructor-profile">Jenis R. </router-link><span>Today at 10:08 AM</span></h6>
													<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="text-md-end">
												<a href="javascript:;" class="btn">Mark as Read</a>
											</div>
										</div>
									</div>
								</div>
								
								<h5>Yesterday</h5>
								<div class="notify-item">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<router-link to="instructor-profile">
													<img class="avatar-img semirounded-circle" src="@/assets/img/user/user3.jpg" alt="User Image">
                                                </router-link>
												<div class="notify-detail">
													<h6><router-link to="instructor-profile">Jesse Stevens</router-link> <span>Yesterday at 9:42 AM</span></h6>
													<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="text-md-end">
												<a href="javascript:;" class="btn">Mark as Read</a>
											</div>
										</div>
									</div>
								</div>
								<div class="notify-item">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<router-link to="instructor-profile">
													<img class="avatar-img semirounded-circle" src="@/assets/img/user/user7.jpg" alt="User Image">
                                                </router-link>
												<div class="notify-detail">
													<h6><router-link to="instructor-profile">Rolands R</router-link> <span>Yesterday at 5:40 AM</span></h6>
													<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="text-md-end">
												<a href="javascript:;" class="btn">Mark as Read</a>
											</div>
										</div>
									</div>
								</div>
								<div class="notify-item">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<router-link to="instructor-profile">
													<img class="avatar-img semirounded-circle" src="@/assets/img/user/user11.jpg" alt="User Image">
                                                </router-link>
												<div class="notify-detail">
													<h6><router-link to="instructor-profile">John Michael</router-link> <span>Yesterday at 7:40 AM</span></h6>
													<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="text-md-end">
												<a href="javascript:;" class="btn">Mark as Read</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>						
					</div>
					<!-- /Notifications List -->	
					
				</div>
			</section>
			<!-- /Notification -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
export default {
  components: {},
  data() {
    return {
      storeAuth: useAuthStore(),
    };
  },
};
</script>