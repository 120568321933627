<template>
  
  <footer
    class="bg-[#58BBDE] text-black 3xl:text-2xl md:py-9 w-full items-center 3xl:justify-center 3xl:flex"
  >
    <div class=" flex flex-col lg:px-40">
      <div
        class="grid grid-cols-1 md:grid-cols-4 md:gap-9 pt-3 mt:pt-10 px-2  3xl:px-0 3xl:px-0"
      >
        <div class="flex flex-col 3xl:space-y-3">
          <h3
            class="mt-5 font-bold md:text-xl 3xl:text-3xl mb-3 md:leading-normal"
          >
            The Music hall
          </h3>
          <a
            href="/"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Accueil</a
          ><a href="/course" class="text-gray-700">Catalogue de formation</a
          ><a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Notre application</a
          ><a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Mentions légales / Confidentialité</a
          >
        </div>
        <div class="flex flex-col 3xl:space-y-3">
          <h3
            class="mt-5 font-bold md:text-xl 3xl:text-3xl mb-3 md:leading-normal"
          >
            Nos liens
          </h3>
          <a href="/blog-masonry" class="text-gray-700">Blog / Actualité</a
          ><a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Toutes nos masterclass</a
          ><a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Devenir Super Prof</a
          >
        </div>
        <div class="flex flex-col 3xl:space-y-3">
          <h3
            class="mt-5 font-bold md:text-xl 3xl:text-3xl mb-3 md:leading-normal"
          >
            Les Ressources
          </h3>
          <a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >Mises à jour</a
          ><a
            href="/#"
            class="router-link-active router-link-exact-active text-gray-700"
            aria-current="page"
            >La communauté</a
          ><a href="/contact" class="text-gray-700">Nous contacter</a>
        </div>
      </div>
      <div
        class="flex justify-between pt-10 px-2 lg:px-40 3xl:px-0 3xl:px-0 3xl:max-w-7xl"
      >
        <p class="text-gray-700">© 2023 THE MUSIC HALL</p>
        <div class="flex space-x-2 text-black">
          <i class="fa fa-instagram"></i><i class="fa fa-twitter"></i>
        </div>
      </div>
    </div>
  </footer>
</template>
