import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useCommentaireStore = defineStore('commentaire', {
  state: () => ({ listCommentCourses: [], notification: null, loading: false }),
  actions: {
    listCourses(slug) {
      axios.get(`/api/courseComments/${slug}`).then((response) => {
        this.listNotifications = response.data.data;
      }).catch(() => {
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    postCourses(body) {
      this.loading = true;
    axios.post(`/api/courseComments`, body).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      console.error("Erreur de recuperation des informations du profile")
    })
  },
    get(id) {
      this.loading = true;
      axios.get(`/api/notifications/${id}`).then((response) => {
        this.notification = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})