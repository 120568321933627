import { router } from '@/router';
import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useCoursesStore = defineStore('course', {
  state: () => ({ listCourses: [], listCoursesProfs: [], listCoursesStudents: [],
    listPaiementCourses: [], bestCoursesProfs: [], course: null, similars: [], loading: false, meta: null, empty: false }),
  actions: {
    list(page) {
      this.loading = true;
      axios.get(`/api/courses?page=${page}`).then((response) => {
        if (page === 1) {
          this.listCourses = response.data.data
          this.meta = response.data.meta
        } else {
          this.listCourses = [...this.listCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    listAuth(page) {
      this.loading = true;
      axios.get(`/api/courses-auth?page=${page}`).then((response) => {
        if (page === 1) {
          this.listCourses = response.data.data
          this.meta = response.data.meta
        } else {
          this.listCourses = [...this.listCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    listUserPaiement(page) {
        this.loading = true;
      axios.get(`/api/userPayments?page=${page}`).then((response) => {
        if (page === 1) {
          this.listPaiementCourses = response.data.data
        } else {
          this.listPaiementCourses = [...this.listPaiementCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    listProf(page) {
      this.loading = true;
      axios.get(`/api/the-prof-courses?page=${page}`).then((response) => {
        if (page === 1) {
          this.listCoursesProfs = response.data.data
        } else {
          this.listCoursesProfs = [...this.listCoursesProfs, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    get(slug) {
        this.loading = true;
      axios.get(`/api/courses/${slug}`).then((response) => {
        this.course = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },

    getAuth(slug) {
      this.loading = true;
      axios.get(`/api/courses-auth/${slug}`).then((response) => {
        this.course = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    paidCoursesStudent(page) {
      this.loading = true;
      axios.get(`/api/paidCourses`).then((response) => {
        if (page === 1) {
          this.listCoursesStudents = response.data.data;
        } else {
          this.listCoursesStudents = [...this.listCoursesStudents, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    bestCoursesProf() {
      this.loading = true;
      axios.get(`/api/best-courses`).then((response) => {
        this.bestCoursesProfs = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    blogSimilaire(slug) {
      axios.get(`/api/articleSimilar/${slug}`).then((response) => {
        this.similars = response.data.data;
      }).catch(() => {
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    payCourses(body) {
      this.loading = true;
      axios.post(`/api/payCourse`, body).then((response) => {
        window.open(`${response.data.lien}`);
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    addCourses(body) {
      this.loading = true;
      axios.post('/api/courses', body).then(() => {
        this.loading = false;
        router.push("/instructor-dashboard");
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    updateCourses(body, id) {
      this.loading = true;
      axios.post(`/api/modifier-courses/${id}`, body).then(() => {
        this.loading = false;
        router.push("/instructor-dashboard");
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
    deleteCourses(id) {
      this.loading = true;
      axios.delete(`/api/courses/${id}`).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des informations du profile")
      })
    },
  },
})