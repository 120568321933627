<template>
<!-- Course Header -->
<div v-if="storeAuth.user !== null"  class="course-student-header" >
<!-- <div v-if="storeAuth.user !== null" :style="storeAuth.user.banner === null ? null : {backgroundImage: 'url(' + storeAuth.user.banner + ')'}" class="course-student-header" style="background-repeat: no-repeat;background-position: center;background-size: cover;"> -->
    <div class="container">
        <div class="student-group">
            <div class="course-group ">
                <div class="course-group-img d-flex">
                    <router-link to="student-profile"><img :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'" alt="" class="img-fluid w-[50px] h-[50px]"></router-link>
                    <div class="d-flex align-items-center">
                        <div class="course-name">
                            <h4><router-link to="student-profile">{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name }}</router-link></h4>
                            <p>{{ storeAuth.user.role }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-student-list">
            <ul>
                <!-- <li :class="currentPath == 'deposit-student-dashboard' ? 'active' : 'notactive'"><router-link to="deposit-student-dashboard">Dashboard</router-link></li> -->
                <li :class="currentPath == 'setting-edit-profile' ? 'active' : 'notactive'"><router-link to="/setting-edit-profile">Profile</router-link></li>
                <li :class="currentPath == 'mes-course' ? 'active' : 'notactive'"><router-link to="/mes-course">Mes cours</router-link></li>
                <!-- <li :class="currentPath == 'course-wishlist' ? 'active' : 'notactive'"><router-link to="course-wishlist">Listes de souhaits</router-link></li> -->
                <li :class="currentPath == 'course-message' ? 'active' : 'notactive'"><router-link to="/course-message">Messages</router-link></li>
                <li :class="currentPath == 'purchase-history' ? 'active' : 'notactive'"><router-link to="/purchase-history">Historique des achats</router-link></li>
            </ul>
        </div>
    </div>
</div>
<!-- /Course Header -->
</template> 
<script>
    import { useAuthStore } from '@stores/auth'
    export default {
        data() {
            return {
            storeAuth: useAuthStore(),
            };
        },
        computed:{
            currentPath() {
                return this.$route.name;
            }
        }
    }
    </script>