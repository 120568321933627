import {createRouter, createWebHistory} from 'vue-router';

import AddCourse from '@/views/pages/pages/course/add-course'
import Blogdetails from '@/views/pages/blog/blog-details'
// import Bloggrid from '@/views/pages/blog/blog-grid'
// import Bloglist from '@/views/pages/blog/blog-list'
import Blogmasonry from '@/views/pages/blog/blog-masonry'
// import Blogmodern from '@/views/pages/blog/blog-modern'
import cart from '@/views/pages/pages/cart.vue'
// import checkout from '@/views/pages/pages/checkout.vue'
// import comesoon from '@/views/pages/pages/error/come-soon.vue'
import coursedetails from '@/views/pages/pages/course/course-details.vue'
// import coursedetails1 from '@/views/pages/pages/course/course-details1.vue'
// import coursedetails2 from '@/views/pages/pages/course/course-details2.vue'
import coursegird from '@/views/pages/pages/course/course-grid.vue'
import courselesson from '@/views/pages/pages/course-lesson.vue'
import courselist from '@/views/pages/pages/course/course-list.vue'
import coursemessage from '@/views/pages/student/course-message.vue'
import courseStudent from '@/views/pages/student/course-student.vue'
// import coursewishlist from '@/views/pages/student/course-wishlist.vue'
import dashboardinstructor from '@/views/pages/instructor/dashboard-instructor.vue'
import depositinstructordashboard from '@/views/pages/instructor/deposit-instructor-dashboard.vue'
import depositinstructor from '@/views/pages/instructor/deposit-instructor.vue'
import depositstudentdashboard from '@/views/pages/student/deposit-student-dashboard.vue'
import depositstudent from '@/views/pages/student/deposit-student.vue'
import error404 from '@/views/pages/pages/error/error-404.vue'
import error500 from '@/views/pages/pages/error/error-500.vue'
// import faq from '@/views/pages/pages/faq.vue'
import forgotpassword from '@/views/pages/pages/forgot-password.vue'
// import helpcenter from '@/views/pages/pages/help-center.vue'
import lessonCourse from '@/views/pages/pages/course/lessons-course.vue'
import index from '@/views/pages/home/dashboardindex.vue'
// import instructorchat from '@/views/pages/instructor/instructor-chat.vue'
import instructorcourse from '@/views/pages/instructor/instructor-course.vue'
import instructordashboard from '@/views/pages/instructor/instructor-dashboard.vue'
import instructordeleteprofile from '@/views/pages/instructor/instructor-delete-profile.vue'
import instructorearnings from '@/views/pages/instructor/instructor-earnings.vue'
import instructoreditprofile from '@/views/pages/instructor/instructor-edit-profile.vue'
import instructoredit from '@/views/pages/instructor/instructor-edit.vue'
// import instructorgrid2 from '@/views/pages/instructor/instructor-grid-2.vue'
// import instructorgrid from '@/views/pages/instructor/instructor-grid.vue'
import instructorlinkedaccount from '@/views/pages/instructor/instructor-linked-account.vue'
import instructorlist from '@/views/pages/instructor/instructor-list.vue'
// import instructornewtickets from '@/views/pages/instructor/instructor-new-tickets.vue'
// import instructornotification from '@/views/pages/instructor/instructor-notification.vue'
// import instructororders from '@/views/pages/instructor/instructor-orders.vue'
import instructorpayouts from '@/views/pages/instructor/instructor-payouts.vue'
import instructorprofileprivacy from '@/views/pages/instructor/instructor-profile-privacy.vue'
import instructorprofile from '@/views/pages/instructor/instructor-profile.vue'
import instructorreviews from '@/views/pages/instructor/instructor-reviews.vue'
import instructorsecurity from '@/views/pages/instructor/instructor-security.vue'
import instructorsocialprofiles from '@/views/pages/instructor/instructor-social-profiles.vue'
// import instructorstudentgrid from '@/views/pages/instructor/instructor-student-grid.vue'
// import instructorstudentlist from '@/views/pages/instructor/instructor-student-list.vue'
import instructortickets from '@/views/pages/instructor/instructor-tickets.vue'
// import jobcategory from '@/views/pages/pages/job-category.vue'
import login from '@/views/pages/pages/login.vue'
import newpassword from '@/views/pages/pages/new-password.vue'
import notifications from '@/views/pages/pages/notifications.vue'
// import pricingplan2 from '@/views/pages/pages/pricing-plan-2.vue'
// import pricingplan from '@/views/pages/pages/pricing-plan.vue'
// import privacypolicy from '@/views/pages/pages/privacy-policy.vue'
import purchasehistory from '@/views/pages/student/purchase-history.vue'
import registerstepfive from '@/views/pages/pages/register-step-five.vue'
import registerstepfour from '@/views/pages/pages/register-step-four.vue'
import registerstepone from '@/views/pages/pages/register-step-one.vue'
import registerstepthree from '@/views/pages/pages/register-step-three.vue'
import registersteptwo from '@/views/pages/pages/register-step-two.vue'
import register from '@/views/pages/pages/register.vue'
import registerProf from '@/views/pages/pages/register-prof.vue'
import settingeditprofile from '@/views/pages/student/setting-edit-profile.vue'
import settingstudentaccounts from '@/views/pages/student/setting-student-accounts.vue'
// import settingstudentbilling from '@/views/pages/student/setting-student-billing.vue'
// import settingstudentdeleteprofile from '@/views/pages/student/setting-student-delete-profile.vue'
// import settingstudentinvoice from '@/views/pages/student/setting-student-invoice.vue'
// import settingstudentnotification from '@/views/pages/student/setting-student-notification.vue'
// import settingstudentpayment from '@/views/pages/student/setting-student-payment.vue'
// import settingstudentprivacy from '@/views/pages/student/setting-student-privacy.vue'
// import settingstudentreferral from '@/views/pages/student/setting-student-referral.vue'
import settingstudentsecurity from '@/views/pages/student/setting-student-security.vue'
// import settingstudentsocialprofile from '@/views/pages/student/setting-student-social-profile.vue'
// import settingstudentsubscription from '@/views/pages/student/setting-student-subscription.vue'
// import settingsupportnewtickets from '@/views/pages/student/setting-support-new-tickets.vue'
// import settingsupporttickets from '@/views/pages/student/setting-support-tickets.vue'
// import settingsupportviewtickets from '@/views/pages/student/setting-support-view-tickets.vue'
import studentprofile from '@/views/pages/student/student-profile.vue'
// import studentsgrid from '@/views/pages/student/students-grid.vue'
// import studentsgrid2 from '@/views/pages/student/students-grid2.vue'
// import studentslist from '@/views/pages/student/students-list.vue'
import support from '@/views/pages/pages/support.vue'
// import termcondition from '@/views/pages/pages/term-condition.vue'
import transactionsinstructor from '@/views/pages/instructor/transactions-instructor.vue'
import transactionsstudent from '@/views/pages/student/transactions-student.vue'
// import underconstruction from '@/views/pages/pages/error/under-construction.vue'
// import verificationcode from '@/views/pages/pages/verification-code.vue'
// import viewinvoice from '@/views/pages/student/view-invoice.vue'
// import wishlist from '@/views/pages/pages/wishlist.vue'
// import withdrawalinstructor from '@/views/pages/instructor/withdrawal-instructor.vue'
// import Hometwo from '@/views/pages/home/hometwo/hometwo.vue'
// import Homethree from '@/views/pages/home/homethree/homethree.vue'
// import Homefour from '@/views/pages/home/homefour/homefour.vue'
import ConfirmPage from '@/views/pages/pages/confirm.vue'
import { useAuthStore } from '@/stores/auth';


const routes = [
  {
    path: '/add-course/:slug?',
    name: 'add-course',
    component: AddCourse,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/confirm/:id_paiement',
    name: 'confirm',
    component: ConfirmPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/lessons/:slug',
    name: 'lessons',
    component: lessonCourse,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/blog-details/:slug',
    name: 'blog-details',
    component: Blogdetails
  },
  // {
  //   path: '/blog-grid',
  //   name: 'blog-grid',
  //   component: Bloggrid
  // },
  // {
  //   path: '/blog-list',
  //   name: 'blog-list',
  //   component: Bloglist
  // },
  {
    path: '/blog-masonry',
    name: 'blog-masonry',
    component: Blogmasonry,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/blog-modern',
  //   name: 'blog-modern',
  //   component: Blogmodern
  // },
  {
    path: '/cart',
    name: 'cart',
    component: cart,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/checkout',
  //   name: 'checkout',
  //   component: checkout
  // },
  // {
  //   path: '/come-soon',
  //   name: 'come-soon',
  //   component: comesoon
  // },
  {
    path: '/course-details/:slug',
    name: 'course-details',
    component: coursedetails,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/course-details1',
  //   name: 'course-details1',
  //   component: coursedetails1
  // },
  // {
  //   path: '/course-details2',
  //   name: 'course-details2',
  //   component: coursedetails2
  // },
  {
    path: '/course-grid',
    name: 'course-grid',
    component: coursegird,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/course-lesson/:slug',
    name: 'course-lesson',
    component: courselesson,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/course',
    name: 'course-list',
    component: courselist,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/course-message',
    name: 'course-message',
    component: coursemessage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/mes-course',
    name: 'mes-course',
    component: courseStudent,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/course-wishlist',
  //   name: 'course-wishlist',
  //   component: coursewishlist
  // },
  {
    path: '/dashboard-instructor',
    name: 'dashboard-instructor',
    component: dashboardinstructor,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deposit-instructor-dashboard',
    name: 'deposit-instructor-dashboard',
    component: depositinstructordashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deposit-instructor',
    name: 'deposit-instructor',
    component: depositinstructor,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deposit-student-dashboard',
    name: 'deposit-student-dashboard',
    component: depositstudentdashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deposit-student',
    name: 'deposit-student',
    component: depositstudent,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: error404
  },
  { path: "/:catchAll(.*)", redirect: '/error-404' },
  {
    path: '/error-500',
    name: 'error-500',
    component: error500
  },
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: faq
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: forgotpassword,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/help-center',
  //   name: 'help-center',
  //   component: helpcenter
  // },
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      headerClass: 'header',
      NavbarClass: 'header-nav',
      requiresAuth: false,
    }
  },
  // {
  //   path: '/instructor-chat',
  //   name: 'instructor-chat',
  //   component: instructorchat
  // },
  {
    path: '/instructor-course',
    name: 'instructor-course',
    component: instructorcourse,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-dashboard',
    name: 'instructor-dashboard',
    component: instructordashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-delete-profile',
    name: 'instructor-delete-profile',
    component: instructordeleteprofile,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-earnings',
    name: 'instructor-earnings',
    component: instructorearnings,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-edit-profile',
    name: 'instructor-edit-profile',
    component: instructoreditprofile,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-edit',
    name: 'instructor-edit',
    component: instructoredit,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/instructor-grid-2',
  //   name: 'instructor-grid-2',
  //   component: instructorgrid2
  // },
  // {
  //   path: '/instructor-grid',
  //   name: 'instructor-grid',
  //   component: instructorgrid
  // },
  {
    path: '/instructor-linked-account',
    name: 'instructor-linked-account',
    component: instructorlinkedaccount,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-list',
    name: 'instructor-list',
    component: instructorlist,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/instructor-new-tickets',
  //   name: 'instructor-new-tickets',
  //   component: instructornewtickets,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  // {
  //   path: '/instructor-notification',
  //   name: 'instructor-notification',
  //   component: instructornotification
  // },
  // {
  //   path: '/instructor-orders',
  //   name: 'instructor-orders',
  //   component: instructororders
  // },
  {
    path: '/instructor-payouts',
    name: 'instructor-payouts',
    component: instructorpayouts,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-profile-privacy',
    name: 'instructor-profile-privacy',
    component: instructorprofileprivacy,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-profile/:pseudo',
    name: 'instructor-profile',
    component: instructorprofile,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/instructor-reviews',
    name: 'instructor-reviews',
    component: instructorreviews,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-security',
    name: 'instructor-security',
    component: instructorsecurity,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/instructor-social-profiles',
    name: 'instructor-social-profiles',
    component: instructorsocialprofiles,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/instructor-student-grid',
  //   name: 'instructor-student-grid',
  //   component: instructorstudentgrid
  // },
  // {
  //   path: '/instructor-student-list',
  //   name: 'instructor-student-list',
  //   component: instructorstudentlist
  // },
  {
    path: '/instructor-tickets',
    name: 'instructor-tickets',
    component: instructortickets,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/job-category',
  //   name: 'job-category',
  //   component: jobcategory
  // },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: newpassword,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: notifications,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/pricing-plan-2',
  //   name: 'pricing-plan-2',
  //   component: pricingplan2
  // },
  // {
  //   path: '/pricing-plan',
  //   name: 'pricing-plan',
  //   component: pricingplan
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'privacy-policy',
  //   component: privacypolicy
  // },
  {
    path: '/purchase-history',
    name: 'purchase-history',
    component: purchasehistory
   },
  {
    path: '/register-step-five',
    name: 'register-step-five',
    component: registerstepfive,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-step-four',
    name: 'register-step-four',
    component: registerstepfour,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-step-one',
    name: 'register-step-one',
    component: registerstepone,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-step-three',
    name: 'register-step-three',
    component: registerstepthree,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-step-two',
    name: 'register-step-two',
    component: registersteptwo,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-prof',
    name: 'register-prof',
    component: registerProf,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/setting-edit-profile',
    name: 'setting-edit-profile',
    component: settingeditprofile,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/setting-student-accounts',
    name: 'setting-student-accounts',
    component: settingstudentaccounts,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/setting-student-billing',
  //   name: 'setting-student-billing',
  //   component: settingstudentbilling
  // },
  // {
  //   path: '/setting-student-delete-profile',
  //   name: 'setting-student-delete-profile',
  //   component: settingstudentdeleteprofile
  // },
  // {
  //   path: '/setting-student-invoice',
  //   name: 'setting-student-invoice',
  //   component: settingstudentinvoice
  // },
  // {
  //   path: '/setting-student-notification',
  //   name: 'setting-student-notification',
  //   component: settingstudentnotification
  // },
  // {
  //   path: '/setting-student-payment',
  //   name: 'setting-student-payment',
  //   component: settingstudentpayment
  // },
  // {
  //   path: '/setting-student-privacy',
  //   name: 'setting-student-privacy',
  //   component: settingstudentprivacy
  // },
  // {
  //   path: '/setting-student-referral',
  //   name: 'setting-student-referral',
  //   component: settingstudentreferral
  // },
  {
    path: '/setting-student-security',
    name: 'setting-student-security',
    component: settingstudentsecurity,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/setting-student-social-profile',
  //   name: 'setting-student-social-profile',
  //   component: settingstudentsocialprofile
  // },
  // {
  //   path: '/setting-student-subscription',
  //   name: 'setting-student-subscription',
  //   component: settingstudentsubscription
  // },
  // {
  //   path: '/setting-support-new-tickets',
  //   name: 'setting-support-new-tickets',
  //   component: settingsupportnewtickets
  // },
  // {
  //   path: '/setting-support-tickets',
  //   name: 'setting-support-tickets',
  //   component: settingsupporttickets
  // },
  // {
  //   path: '/setting-support-view-tickets',
  //   name: 'setting-support-view-tickets',
  //   component: settingsupportviewtickets
  // },
  {
    path: '/student-profile',
    name: 'student-profile',
    component: studentprofile,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/students-grid',
  //   name: 'students-grid',
  //   component: studentsgrid
  // },
  // {
  //   path: '/students-grid2',
  //   name: 'students-grid2',
  //   component: studentsgrid2
  // },
  // {
  //   path: '/students-list',
  //   name: 'students-list',
  //   component: studentslist
  // },
  // {
  //   path: '/support',
  //   name: 'support',
  //   component: support
  // },
  {
    path: '/contact',
    name: 'contact',
    component: support,
    meta: {
      requiresAuth: false,
    }
  },
  // {
  //   path: '/term-condition',
  //   name: 'term-condition',
  //   component: termcondition
  // },
  {
    path: '/transactions-instructor',
    name: 'transactions-instructor',
    component: transactionsinstructor,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/transactions-student',
    name: 'transactions-student',
    component: transactionsstudent,
    meta: {
      requiresAuth: true,
    }
  },
  // {
  //   path: '/under-construction',
  //   name: 'under-construction',
  //   component: underconstruction
  // },
  // {
  //   path: '/verification-code',
  //   name: 'verification-code',
  //   component: verificationcode
  // },
  // {
  //   path: '/view-invoice',
  //   name: 'view-invoice',
  //   component: viewinvoice
  // },
  // {
  //   path: '/wishlist',
  //   name: 'wishlist',
  //   component: wishlist
  // },
  // {
  //   path: '/withdrawal-instructor',
  //   name: 'withdrawal-instructor',
  //   component: withdrawalinstructor
  // },
//   {
//     path: '/index-two',
//     name: 'index-two',
//     component: Hometwo,
//     meta: {
//       headerClass: 'header header-two',
//       NavbarClass: 'header-nav',
//  }
//   },
//   {
//     path: '/index-three',
//     name: 'index-three',
//     component: Homethree,
//     meta: {
//       headerClass: 'header-three',
//       NavbarClass: 'header-nav-three',
//     }
//   },
  // {
  //   path: '/index-four',
  //   name: 'index-four',
  //   component: Homefour,
  //   meta: {
  //     headerClass: 'header-five',
  //     NavbarClass: 'header-nav',
  //   }
  // },
];

// export const router = createRouter({
//     history: createWebHistory(),
//     linkActiveClass: 'active',
//     routes
// });
// router.beforeEach((to, from, next) => {
//   // Scroll to the top of the page
//   window.scrollTo({ top: 0, behavior: 'smooth' });

//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     console.log(useAuthStore().isLogin())
//     if (!(useAuthStore().isLogin())) {
//       router.push('/login')
//     } else {
//       next()
//     }
//   } else {
//     console.log('lolo')
//     next()
//   }
// });


export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Show the preloader
  document.querySelector('.preloader').style.display = 'flex';

  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!(useAuthStore().isLogin())) {
      router.push('/login');
    } else {
      // Add a delay (simulate async operations like fetching data)
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Hide the preloader
      document.querySelector('.preloader').style.display = 'none';
      next();
    }
  } else {
    // Add a delay (simulate async operations like fetching data)
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Hide the preloader
    document.querySelector('.preloader').style.display = 'none';
    next();
  }
});