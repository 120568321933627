<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2 v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layouts2>
    <layoutsloginborder v-else></layoutsloginborder>

    <cartbreadcrumb></cartbreadcrumb>
    <!-- Cart -->
    <section class="course-content cart-widget">
      <div class="container">
        <div class="student-widget">
          <div class="student-widget-group">
            <div class="row">
              <div class="col-lg-12">
                <div class="cart-head">
                  <h4>Votre panier ({{ storeCart.listCart.length }} cours)</h4>
                </div>
                <div class="cart-group">
                  <div v-if="storeCart.listCart.length > 0" class="row">
                    <div v-for="(item, index) in storeCart.listCart" :key="index" class="col-lg-12 col-md-12 d-flex">
                      <div class="course-box course-design list-course d-flex">
                        <div class="product">
                          <div class="product-img">
                            <router-link :to="{ name: 'course-details', params: { slug: item.course_slug } }">
                              <img
                                class="img-fluid"
                                alt=""
                                :src="item.course_image"
                              />
                            </router-link>
                            <div v-if="item.course_price !== 0 && item.course_price !== ''" class="price">
                              <h3>{{ item.course_price }}€ <span v-if="item.course_old_price !== null && item.course_old_price !== ''">{{ item.course_old_price }}€</span></h3>
                            </div>
                            <div v-else class="price">
                              <h3 class="free-color">GRATUIT</h3>
                            </div>
                          </div>
                          <div class="product-content">
                            <div class="head-course-title">
                              <h3 class="title">
                                <router-link to="course-details"
                                  >{{ item.course_title }}</router-link
                                >
                              </h3>
                            </div>
                            <div
                              class="course-info d-flex align-items-center border-bottom-0 pb-0"
                            >
                              <div class="rating-img d-flex align-items-center">
                                <img src="@/assets/img/icon/icon-01.svg" alt="" />
                                <p>{{ item.course_total_lesson }} Leçon(s)</p>
                              </div>
                              <div class="course-view d-flex align-items-center">
                                <img src="@/assets/img/icon/icon-02.svg" alt="" />
                                <p>{{ item.course_duration }}</p>
                              </div>
                            </div>
                            <!-- <div class="rating">
                              <i class="fas fa-star filled me-1"></i>
                              <i class="fas fa-star filled me-1"></i>
                              <i class="fas fa-star filled me-1"></i>
                              <i class="fas fa-star filled me-1"></i>
                              <i class="fas fa-star me-1"></i>
                              <span class="d-inline-block average-rating"
                                ><span>4.0</span> (15)</span
                              >
                            </div> -->
                            <div v-if="item.course_note > 0" class="rating">
                              <i v-for="items in item.course_note" :key="items" class="fas fa-star filled me-1"></i>
                              <i v-for="items in (5 - item.course_note)" :key="items" class="fas fa-star me-1"></i>
                              <span class="d-inline-block average-rating"
                                >{{ item.course_note }}</span>
                            </div>
                            <div v-else class="rating">
                              <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                              <span class="d-inline-block average-rating"
                                >{{ item.course_note }}</span
                              >
                            </div>
                          </div>
                          <div class="cart-remove">
                            <button @click="deleteCart(item.course_id)" class="btn btn-primary">Supprimer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="flex flex-col items-center"
                    >
                    <!-- <img
                      class="flex flex-col items-center h-screen"
                      src="@/assets/empty.png"
                      alt="Image"
                      class="max-w-full max-h-full"
                    /> -->
                    <p class="mt-4">
                      Votre panier est vide
                    </p>
                  </div>
                </div>
                <div class="cart-total">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="cart-subtotal">
                        <p>Total <span>{{ storeCart.totalPrice }} €</span></p>
                      </div>
                    </div>
                    <div v-if="storeCart.listCart.length > 0" class="col-lg-6 col-md-6">
                      <div class="check-outs">
                        <button @click="pay" class="btn btn-primary"
                          >Payer</button
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="condinue-shop">
                        <router-link to="course" class="btn btn-primary"
                          >Ajouter d'autres cours</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Cart -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
import { useCartStore } from '@stores/cart'
import { router } from '@/router';
export default {
  components: {},
  data() {
    return {
      storeAuth: useAuthStore(),
      storeCart: useCartStore(),
    };
  },
  methods: {
    async deleteCart(courses){
      await this.storeCart.deleteByCart({ course_id: courses })
    },
    async pay(){
      if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
        await this.storeCart.payCourses()
      } else{
        router.push("/login")
      }
    }
  },
};
</script>