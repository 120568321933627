import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useReversementStore = defineStore('reversement', {
  state: () => ({ listReversements: 0, profReversements: [], reversement: 0, loading: false, loadingAdd: false }),
  actions: {
    listReversementProf() {
      this.loading = true;
      axios.get(`/api/user-reversement`).then((response) => {
        this.profReversements = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Erreur de recuperation des reversements")
      })
    },
    
    addReversementProf(body) {
      this.loadingAdd = true;
      axios.post(`/api/reversement`, body).then(() => {
        this.listReversementProf()
        toast.success("Demande envoye", {
          autoClose: 3000,
        }); 
        this.loadingAdd = false;
      }).catch((e) => {
        this.loadingAdd = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Erreur de recuperation des informations du profile ", e)
      })
    },
  },
})