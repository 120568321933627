<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <!-- <registerbanner></registerbanner> -->

      <div class="login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox register-box">
            <div class="img-logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <router-link to="login">Retourner à l'accueil</router-link>
              </div>
            </div>
            <h1>Finalisez votre profil enseignant</h1>
            <div class="row">
              <div class="col-lg-5">
                <div class="profile-box">
                  <div class="circle-bar circle-bar1 text-center">
                    <div class="circle-graph1" data-percent="100">
                      <circle-progress :percent="100" />
                      <p>100% <span>3 de 3</span></p>
                    </div>
                  </div>
                  <h3>Complément de profil</h3>
                  <div class="personal-detail d-flex align-items-center">
                    <span>1</span>
                    <div class="personal-text">
                      <h4>Catégories et Profil</h4>
                      <p class="mb-0">Configurer votre profil et choisissez votre catégorie</p>
                    </div>
                  </div>
                  <div class="personal-detail d-flex align-items-center">
                    <span>2</span>
                    <div class="personal-text">
                      <h4>Profils sociaux</h4>
                      <p class="mb-0">Configurer les liens de vos profils sociaux</p>
                    </div>
                  </div>
                  <div class="personal-detail d-flex align-items-center">
                    <span class="active-color active-bar">3</span>
                    <div class="personal-text">
                      <h4>Biographie</h4>
                      <p class="mb-0">Entrez votre biographie ici</p>
                    </div>
                  </div>
                  <!-- 
                  <div class="personal-detail d-flex align-items-center">
                    <span>4</span>
                    <div class="personal-text">
                      <h4>Link Accounts</h4>
                      <p class="mb-0">Setup Your Linked Accounts</p>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="col-lg-7">
                <div class="personal-form">
                  <h4>Biographie</h4>
                  <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="form-group mb-0">
                        <label class="add-course-label">Biographie</label>
                        <SummernoteEditor
                          v-model="bio"
                          @update:modelValue="summernoteChange($event)"
                          @summernoteImageLinkInsert="summernoteImageLinkInsert"
                        />
                      </div>
                      <div class="col-lg-12 mt-4">
                        <div class="btn-group d-flex">
                          <div class="back-btn">
                            <router-link to="register-step-two" class="btn btn-back"
                              >Retour</router-link
                            >
                          </div>
                          <div v-if="isCheck === true" class="next-btn">
                            <button class="btn btn-primary btn-start">
                              <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                                <span class="sr-only">Chargement...</span>
                              </div>
                              <span v-else>
                                Soumettre
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { useAuthStore } from '@stores/auth'
export default {
  components: {
    CircleProgress,
  },
  data() {
    return {
      bio: "",
      storeAuth: useAuthStore(),
      Select: ["Select", "India", "America", "London"],
    };
  },
  computed: {
    isCheck(){
      if(this.bio === ''){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    async submitForm() {
      this.storeAuth.prof.description = this.bio
      await this.storeAuth.updateProf()
    },
    summernoteChange(newValue) {
      console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      console.log("summernoteImageLinkInsert()", args);
    },
  },
};
</script>
