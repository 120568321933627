<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructorborder></layoutsinstructorborder>

    <instructoredit v-if="storeProfs.prof !== null"></instructoredit>
    <!-- Course Content -->
    <section v-if="storeProfs.prof !== null" class="page-content course-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <!-- Overview -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">À propos de moi</h5>
                <p v-html="storeProfs.prof.description"></p>
              </div>
            </div>
            <!-- /Overview -->

            <!-- Courses Content -->
            <div class="card education-sec" v-if="storeCourses.listCoursesProfs.length > 0">
              <div class="card-body pb-0">
                <h5 class="subs-title">Cours du professeur</h5>
                <div class="row">
                  <div v-for="(item, index) in storeCourses.listCoursesProfs" :key="index" class="col-lg-6 col-md-6 d-flex">
                    <div class="course-box course-design d-flex">
                      <div class="product">
                        <div class="product-img">
                          <router-link to="course-details">
                            <img
                              class="img-fluid"
                              alt=""
                              :src="item.image"
                            />
                          </router-link>
                          <div class="price">
                            <h3 v-if="item.price !== 0">{{ item.price }} <span v-if="item.old_price !==' €' && item.old_price !==null">{{ item.old_price }}</span></h3>
                            <h3 v-else class="free-color">GRATUIT</h3>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="course-group d-flex">
                             
                          </div>
                          <h3 class="title instructor-text ">
                            <router-link to="course-details"
                              >{{ item.title }}</router-link
                            >
                          </h3>
                          <div class="course-info d-flex align-items-center border-0 m-0 ">
                            <div class="rating-img d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-01.svg" alt="" />
                              <p class="text-center">{{ item.total_lessons }} Leçon(s)</p>
                            </div>
                             
                          </div>
                           
                          <div v-if="item.note > 0" class="rating">
                              <i v-for="items in item.note" :key="items" class="fas fa-star filled me-1"></i>
                              <i v-for="items in (5 - item.note)" :key="items" class="fas fa-star me-1"></i>
                              <span class="d-inline-block average-rating"
                                >{{ item.note }}</span>
                            </div>
                          <div v-else class="rating">
                            <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              >{{ item.note }}</span
                            >
                          </div>
                          <div class="all-btn all-category d-flex align-items-center">
                            <button @click="pay(item.id)" class="btn btn-primary"
                              >Acheter maintenant</button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div @click="storeCourses.loading === true ? page : page++" v-if="storeCourses.empty !== true" class="load-more text-center">
                  <div v-if="storeCourses.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span class="btn btn-primary" v-else>
                    Voir plus
                  </span>
                </div>
              </div>
            </div>
            <!-- /Courses Content -->

            <!-- Reviews -->
            <div class="card review-sec">
              <div class="card-body">
                <h5 class="subs-title">Quelques avis</h5>
                <div class="row">
                  <div v-for="(item, index) in storeNotes.profNotes" :key="index" :class="{ 'border-r-2': index % 2 == 0 }" class="review-item col-lg-6">
                    <div class="instructor-wrap border-0 m-0">
                      <div class="about-instructor">
                        <div class="abt-instructor-img">
                          <router-link to="instructor-profile">
                            <img
                              :src="item.author_img"
                              alt="img"
                              class="img-fluid"
                            />
                          </router-link>
                        </div>
                        <div class="instructor-detail">
                          <h5>
                            <router-link to="instructor-profile">{{ item.author_first_name }} {{ item.author_last_name }}</router-link>
                          </h5>
                        </div>
                      </div>
                      <div v-if="item.note > 0" class="rating">
                        <i v-for="items in item.note" :key="items" class="fas fa-star filled me-1"></i>
                        <i v-for="items in (5 - item.note)" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.note }}</span>
                      </div>
                      <div v-else class="rating">
                        <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          >{{ item.note }}</span
                        >
                      </div>
                    </div>
                    <p class="rev-info">
                      “ {{ item.commentaire }} “
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Reviews -->
 
          </div>

          <div class="col-lg-4">
            <div class="card overview-sec">
								<div class="card-body overview-sec-body">
									<h5 class="subs-title">Catégories</h5>
									<div class="sidebar-tag-labels">
										<ul class="list-unstyled">
											<li v-for="(item, index) in storeProfs.prof.categories" :key="index" >
                        <a href="#" class="">{{ item.name }}</a>
                      </li>
										</ul>
									</div>
								</div>
							</div>
            <!-- Right Sidebar Profile Overview -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">Aperçu du profil</h5>
                <div class="rating-grp">
                  <div v-if="storeProfs.prof.notes > 0" class="rating">
                      <i v-for="items in storeProfs.prof.notes" :key="items" class="fas fa-star filled me-1"></i>
                      <i v-for="items in (5 - storeProfs.prof.notes)" :key="items" class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        >{{ storeProfs.prof.notes }}</span>
                    </div>
                    <div v-else class="rating">
                      <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        >{{ storeProfs.prof.notes }}</span
                      >
                    </div> 
                </div>
                <div class="profile-overview-list">
                  <div class="list-grp-blk d-flex">
                    <div class="flex-shrink-0">
                      <img
                        src="@/assets/img/instructor/courses-icon.png"
                        alt="Courses"
                      />
                    </div>
                    <div class="list-content-blk flex-grow-1 ms-3">
                      <h5>{{ storeProfs.prof.total_courses }}</h5>
                      <p>Cours</p>
                    </div>
                  </div>
                  <div class="list-grp-blk d-flex">
                    <div class="flex-shrink-0">
                      <img
                        src="@/assets/img/instructor/ttl-stud-icon.png"
                        alt="Total Students"
                      />
                    </div>
                    <div class="list-content-blk flex-grow-1 ms-3">
                      <h5>{{ storeProfs.prof.total_etudiant }}</h5>
                      <p>Total étudiants</p>
                    </div>
                  </div>
                  <!-- <div class="list-grp-blk d-flex">
                    <div class="flex-shrink-0">
                      <img
                        src="@/assets/img/instructor/review-icon.png"
                        alt="Reviews"
                      />
                    </div>
                    <div class="list-content-blk flex-grow-1 ms-3">
                      <h5>12,230</h5>
                      <p>Reviews</p>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- /Right Sidebar Profile Overview -->

            <!-- Right Contact Details -->
            <div  class="card overview-sec">
              <div v-if="storeAuth.isLogin() === true && storeAuth.user !== null" class="card-body">
                <div @click="contactProf" class="load-more text-center">
                  <span class="btn btn-primary">
                    Contacter le prof
                  </span>
                </div>
                <!-- <h5 class="subs-title">Contact Details</h5>
                <div class="contact-info-list">
                  <div class="edu-wrap">
                    <div class="edu-name">
                      <span
                        ><img
                          src="@/assets/img/instructor/email-icon.png"
                          alt="Address"
                      /></span>
                    </div>
                    <div class="edu-detail">
                      <h6>Email</h6>
                      <p><a href="javascript:;">jennywilson@example.com</a></p>
                    </div>
                  </div>
                  <div class="edu-wrap">
                    <div class="edu-name">
                      <span
                        ><img
                          src="@/assets/img/instructor/address-icon.png"
                          alt="Address"
                      /></span>
                    </div>
                    <div class="edu-detail">
                      <h6>Address</h6>
                      <p>877 Ferry Street, Huntsville, Alabama</p>
                    </div>
                  </div>
                  <div class="edu-wrap">
                    <div class="edu-name">
                      <span
                        ><img
                          src="@/assets/img/instructor/phone-icon.png"
                          alt="Address"
                      /></span>
                    </div>
                    <div class="edu-detail">
                      <h6>Phone</h6>
                      <p><a href="javascript:;">+1(452) 125-6789</a></p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- /Right Contact Details -->
          </div>
        </div>
      </div>
    </section>
    <!-- /Pricing Plan -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useProfStore } from '@stores/profs'
  import { useCoursesStore } from '@stores/courses'
  import { useMessagesStore } from '@stores/messages'
  import { useNoteStore } from '@stores/notes'
  import { useAuthStore } from '@stores/auth'
  import { router } from '@/router';
  export default {
    data() {
      return {
        storeCourses: useCoursesStore(),
        storeProfs: useProfStore(),
        storeMessage: useMessagesStore(),
        storeNotes: useNoteStore(),
        storeAuth: useAuthStore(),
        commentaire: "",
        isComment: false,
        currentRating: 0,
        maxRating: 5,
        page: 1
      };
    },
    computed: {
      stars: function() {
          return Array.from({ length: this.maxRating }, (_, index) => index + 1);
      },
      getRating: {
        get(){
          return this.currentRating
        },
        set(value){
          this.currentRating = value
        }
      }
    },
    watch: {
      async page(newValue) {
        await this.storeCourses.listProf(this.storeProfs.prof.id, newValue);
      }
    },
    async mounted() {
      await this.storeNotes.listNoteProf(router.currentRoute.value.params.pseudo)
      await this.storeCourses.listProf(this.storeProfs.prof.id, this.page);
    },

    async beforeMount() {
      await this.storeProfs.get(router.currentRoute.value.params.pseudo)
    },

    methods: {
      async contactProf(){
        await this.storeMessage.startMessage({
          receiver_id: this.storeProfs.prof.id
        });
      },
      async pay(id){
        if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
        await this.storeCourses.payCourses(
          {
            course_id: id,
          }
        )
      } else{
        router.push("/login")
      }
      }
    },
  };
</script>