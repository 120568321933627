<template>
    <!-- Main Wrapper -->
    <div v-if="storeAuth.user !== null" class="main-wrapper">
        
        <layouts></layouts>
			<courseheader ></courseheader>
            <!-- Course Lesson -->
			<section class="page-content course-sec course-message">
				<div class="container">
					<div class="student-widget message-student-widget">
						<div class="student-widget-group"> 
							<div class="col-md-12">
								<div class="chat-window" v-if="storeMessage.users.length > 0">
								
									<!-- Chat Left -->
									<div class="chat-cont-left">
										<div class="chat-users-list">
											<div class="chat-scroll">
												<a style="cursor: pointer;" @click="getMessages(item)" v-for="(item, index) in storeMessage.users" :key="index" class="media d-flex place-items-center">
													<div class="media-img-wrap flex-shrink-0">
														<div class="avatar avatar-away">
															<img :src="item.photo" alt="User Image" class="avatar-img rounded-circle">
														</div>
													</div>
													<div class="media-body flex-grow-1">
														<div>
															<div class="user-name">{{ item.first_name }} {{ item.last_name }}</div>
														</div>
													</div>
												</a> 
											</div>
										</div>
									</div>
									<!-- /Chat Left -->
								
									<!-- Chat Right -->
									<div class="chat-cont-right" v-if="user !== ''">
										<div class="chat-header">
											<a id="back_user_list" href="javascript:void(0)" class="back-user-list">
												<i class="material-icons">chevron_left</i>
											</a>
											<div v-if="user !== ''" class="media d-flex">
												<div class="media-img-wrap flex-shrink-0">
													<div class="avatar avatar-online">
														<img :src="user.photo" alt="User Image" class="avatar-img rounded-circle">
													</div>
												</div>
												<div class="media-body flex-grow-1">
													<div class="user-name">{{ user.first_name }} {{ user.last_name }} </div>
												</div>
											</div>
										</div>
										<div class="chat-body">
											<div class="chat-scroll">
												<ul class="list-unstyled">
													<li v-for="(item, index) in storeMessage.listMessages" :key="index" :class="item.sender !== storeAuth.user.pseudo ? 'media received d-flex' : 'media sent d-flex'">
														<div class="media-body flex-grow-1">
															<div class="msg-box">
																<div class="msg-bg">
																	<p>{{ item.content }}</p>
																</div> 
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<div class="chat-footer">
											<div class="input-group"> 
												<input type="text" v-model="content" class="input-msg-send form-control" placeholder="Votre message...">
												<button v-if="user !== ''" @click="send" type="button" class="btn btn-primary msg-send-btn rounded-pill"><img src="@/assets/img/send-icon.svg" alt="" ></button>
											</div>
										</div>
									</div>
									<div class="chat-cont-right d-flex  justify-content-center align-items-center" v-else>
										Veuillez selectionner une discussion
									</div>
									<!-- /Chat Right -->
								</div>	
								<div v-else class="d-flex align-items-center justify-content-center py-9">
									Vous n'avez aucun contact 
								</div>			
							</div>													
						</div>													
					</div>	
				</div>
			</section>
			<!-- /Course Lesson -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import { useMessagesStore } from '@stores/messages'
import { useAuthStore } from '@stores/auth'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {},
  data() {
    return {
		storeMessage: useMessagesStore(),
		storeAuth: useAuthStore(),
		content: '',
		user: '',
    };
  },

  computed: {},

  async created() {
	await this.storeMessage.getUser();
  },
  methods: {
	async getMessages(user){
		await this.storeMessage.getMessages(user.id);
		this.user = user;
	},
	async send(){
		if (this.content !== '') {
			await this.storeMessage.sendMessage({
				content: this.content,
				receiver_id: this.user.id,
			})
			this.content = ''
		} else {
			toast.error("Veuillez saisir un message.", {
				autoClose: 3000,
			});
		}
			
	}
  },
};
</script>